import axios, { AxiosResponse } from 'axios';
import { ApiUrlGed } from '@/constants/ApiUrls';
import type { RechercheGed, ResponseModel } from '@/models';
import { apiConfig, apiConfigSansHeader, apiConfigPDF } from '@/config/UtilisateurConfig';

export default {
  async ajouterDocumentGed(
    type_document: string,
    nom: string,
    donnees: string,
    nom_detenteur: string,
    adresse_detenteur: string,
    detenteur_code_postal: string,
    code_client: string,
    code_orga: string
  ): Promise<AxiosResponse<ResponseModel>> {
    const date = new Date();
    let stringDate = '';
    stringDate =
      date.getFullYear() +
      (date.getMonth() > 8 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1)).toString() +
      (date.getDate() > 9 ? date.getDate() : '0' + date.getDate());

    const donneesEnvoyes = {
      metadata: [
        {
          key: 'destaddr1',
          value: adresse_detenteur,
        },
        {
          key: 'destcodepostal',
          value: detenteur_code_postal,
        },
        {
          key: 'destlu',
          value: '0',
        },
        {
          key: 'notifenvoyee',
          value: 'non',
        },
        {
          key: 'nomclient',
          value: nom_detenteur,
        },
        {
          key: 'dateref',
          value: stringDate,
        },
        {
          key: 'typedoc',
          value: type_document,
        },
        {
          key: 'codeclient',
          value: code_client,
        },
        {
          key: 'codeorga',
          value: code_orga,
        },
      ],
      file: {
        name: nom,
        lastModified: '2022-02-08T08:38:58.326Z',
        content: donnees,
      },
    };

    return axios.post(`${ApiUrlGed}/document`, donneesEnvoyes, apiConfig.value);
  },

  async fetchAllGed(id: number): Promise<AxiosResponse<ResponseModel>> {
    return axios.get(`${ApiUrlGed}/typesdocumentsnonlus`, {
      params: { idCheptel: id },
      headers: apiConfigSansHeader.value,
    });
  },

  async fetchAllDocByFiltre(recherche: RechercheGed): Promise<AxiosResponse<ResponseModel>> {
    interface Filtre {
      field: string;
      value: string;
      filterFunction: number;
    }
    interface SearchBody {
      filter: Filtre[];
      orderBy?: {
        field: string;
        order: number;
        function: number;
        functionSpecified: boolean;
      }[];
      first?: number;
      count?: number;
      fullTextFilter?: {
        value: string;
      };
      idCheptel: number;
    }
    const filtres: {
      field: string;
      value: string;
      filterFunction: number;
    }[] = [];

    /* Filtre type doc */
    if (recherche.typesDocumentsGed && recherche.periodeSelectionnee?.length != 0) {
      recherche.typesDocumentsGed.forEach((element) => {
        filtres.push({ field: 'typedoc', value: element, filterFunction: 1 });
      });
    }

    /* Filtre date */
    if (recherche.dateDebut != '') {
      filtres.push({ field: 'dateref', value: recherche.dateDebut, filterFunction: 3 });
    }

    if (recherche.dateFin != '') {
      filtres.push({ field: 'dateref', value: recherche.dateFin, filterFunction: 2 });
    }

    const searchBody: SearchBody = {
      idCheptel: recherche.idCheptel,
      filter: filtres,
      count: recherche.count ?? 0,
      first: recherche.first ?? 0,
    };

    if (recherche.pleinTexte !== '') {
      searchBody.fullTextFilter = { value: recherche.pleinTexte };
    }

    /* Order by */
    if (recherche.sortField && recherche.sortOrder) {
      searchBody.orderBy = [
        {
          field: recherche.sortField,
          order: recherche.sortOrder === -1 ? 1 : 0,
          function: 0,
          functionSpecified: false,
        },
      ];
    }

    return axios.post(`${ApiUrlGed}/searchDocuments`, searchBody, apiConfig.value);
  },

  async fetchDocumentGed(nuged: string): Promise<AxiosResponse> {
    return axios.get(`${ApiUrlGed}/documentFile`, {
      params: { nuged: nuged },
      headers: apiConfigPDF.value,
      responseType: 'blob',
    });
  },

  async fetchInfoDocumentGed(nuged: string): Promise<AxiosResponse> {
    const url = new URL(`${ApiUrlGed}/document`);

    const queryParameters: { nuged: string } = {
      nuged: nuged,
    };
    url.search = new URLSearchParams(queryParameters).toString();
    return axios.get(`${url.toString()}`, apiConfig.value);
  },

  async fetchTypeDeDocumentGed(): Promise<AxiosResponse<ResponseModel>> {
    return axios.get(`${ApiUrlGed}/reftypesdocuments`, apiConfig.value);
  },

  async supprimerDocument(id: string): Promise<AxiosResponse<ResponseModel>> {
    return axios.delete(`${ApiUrlGed}/document`, { params: { nuged: id }, headers: apiConfigSansHeader.value });
  },

  async mettreLuDocument(id: string): Promise<AxiosResponse<ResponseModel>> {
    const tmp = {
      nuged: id,
      metadata: [
        {
          key: 'destlu',
          value: '1',
        },
      ],
    };
    return axios.put(`${ApiUrlGed}/document`, tmp, apiConfig.value);
  },

  async fetchThemesDocumentsByCheptel(num_cheptel: string): Promise<AxiosResponse<ResponseModel>> {
    const url = new URL(`${ApiUrlGed}/themesdocuments`);

    const queryParameters: { numeroExploitation: string } = {
      numeroExploitation: num_cheptel,
    };
    url.search = new URLSearchParams(queryParameters).toString();
    return axios.get(url.toString(), apiConfig.value);
  },
};
