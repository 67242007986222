import type {
  TypeDocumentGed,
  DocumentGed,
  Ged,
  Cheptel,
  ThemeDocument,
  GenericFileGed,
  RechercheGed,
  EvenementLazyLoading,
} from '@/models';
import { MenuItem } from 'primevue/menuitem';
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute, LocationQueryRaw } from 'vue-router';
import TypeFileGed from '@/constants/TypeFileGed';
import { cloneDeep, isEmpty } from 'lodash-es';
import FunctionUtils from '@/utils/FunctionUtils';

export default function GedService() {
  const store = useStore();
  const router = useRouter();
  const route = useRoute();
  const { dynamicSort } = FunctionUtils();

  const donneesDocumentGed = computed((): any => {
    return store.getters['GedModule/getDonneesDocumentGed'];
  });

  const listeGed = computed((): Ged[] => {
    return store.getters['GedModule/getListeGed'];
  });

  const listeDocumentGed = computed((): DocumentGed[] => {
    return store.getters['GedModule/getListeDocumentGed'];
  });

  const listeTypeDocumentGed = computed((): TypeDocumentGed[] => {
    return store.getters['GedModule/getListeTypeDocumentGed'];
  });

  const nbResultat = computed((): number => {
    return store.getters['GedModule/getNbResultat'];
  });

  async function ajouterDocumentGed(
    type_document: string,
    nom: string,
    donnees: string,
    nom_detenteur: string,
    adresse_detenteur: string,
    detenteur_code_postal: string,
    code_client: string,
    code_orga: string
  ) {
    await store.dispatch('GedModule/ajouterDocumentGed', {
      type_document,
      nom,
      donnees,
      nom_detenteur,
      adresse_detenteur,
      detenteur_code_postal,
      code_client,
      code_orga,
    });
  }

  async function supprimerDocument(id: string) {
    await store.dispatch('GedModule/supprimerDocument', { id });
  }

  async function searchFichier(recherche: RechercheGed) {
    await store.dispatch('GedModule/searchFichier', {
      recherche,
    });
  }

  async function fetchAllGed(id_cheptel: number) {
    await store.dispatch('GedModule/fetchAllGed', { id_cheptel });
  }

  async function fetchDocumentGed(nuged: string) {
    await store.dispatch('GedModule/fetchDocumentGed', { nuged });
  }

  async function telechargerDocumentGed(nuged: string, nom: string) {
    await store.dispatch('GedModule/telechargerDocumentGed', { nuged, nom });
  }

  async function fetchTypeDeDocumentGed() {
    await store.dispatch('GedModule/fetchTypeDeDocumentGed');
  }

  async function mettreLuDocument(nuged: string) {
    await store.dispatch('GedModule/mettreLuDocument', { nuged });
  }

  const exploitationSelectionne = computed((): Cheptel => {
    return store.getters['GedModule/getExploitationSelectionne'];
  });
  async function storeExploitationSelectionne(exploitation: Cheptel) {
    await store.dispatch('GedModule/storeExploitationSelectionne', { exploitation });
  }
  async function clearExploitationSelectionne() {
    await store.commit('GedModule/setExploitationSelectionne', {});
  }

  const navigationActiveGed = computed((): MenuItem[] => {
    return store.getters['GedModule/getNavigationActiveGed'];
  });
  async function storeNavigationActiveGed(filAriane: MenuItem[]) {
    await store.commit('GedModule/setNavigationActiveGed', filAriane);
  }

  const documentSelectionne = computed((): DocumentGed => {
    return store.getters['GedModule/getDocumentSelectionne'];
  });
  async function storeDocumentSelectionne(document: DocumentGed) {
    await store.dispatch('GedModule/storeDocumentSelectionne', { document });
  }

  const lazyParamsDatatableGed = computed((): EvenementLazyLoading => {
    return store.getters['GedModule/getLazyParamsDatatableGed'];
  });
  async function storeLazyParamsDatatableGed(lazyParams: EvenementLazyLoading) {
    await store.dispatch('GedModule/storeLazyParamsDatatableGed', { lazyParams });
  }
  async function clearLazyParamsDatatableGed() {
    await store.dispatch('GedModule/storeLazyParamsDatatableGed', { lazyParams: {} });
  }

  const themesDocuments = computed((): ThemeDocument[] => {
    return store.getters['GedModule/getThemesDocuments'];
  });
  async function fetchThemesDocumentsByCheptel(num_cheptel: string) {
    await store.dispatch('GedModule/fetchThemesDocumentsByCheptel', { num_cheptel });
  }
  async function clearThemesDocuments() {
    await store.commit('GedModule/setThemesDocuments', []);
  }

  const exploitationsSelectionnees = computed((): Cheptel[] => {
    return store.getters['GedModule/getExploitationsSelectionnes'];
  });
  async function storeExploitationsSelectionnes(exploitation: Cheptel[]) {
    await store.commit('GedModule/setExploitationsSelectionnes', exploitation);
  }

  const rechercheEnCours = computed((): RechercheGed => {
    return store.getters['GedModule/getRechercheEnCours'];
  });
  async function storeRechercheEnCours(recherche: RechercheGed) {
    await store.commit('GedModule/setRechercheEnCours', recherche);
  }

  function pushToFile(file: GenericFileGed, conserverQuery: boolean | undefined = true) {
    let lastQuery = {};
    if (conserverQuery) lastQuery = { ...route.query };
    switch (file.type_file) {
      case TypeFileGed.THEME:
        router.push({ name: 'Ged', query: { ...lastQuery, theme: file.id } });
        break;
      case TypeFileGed.DOSSIER:
        router.push({ name: 'Ged', query: { ...lastQuery, dossier: file.nom } });
        break;
      case TypeFileGed.FICHIER:
        afficherPdf(file);
        break;
      default:
        break;
    }
  }

  async function afficherPdf(data: GenericFileGed) {
    const document = listeDocumentGed.value.find((d) => d.id === data.id)!;
    if (document) storeDocumentSelectionne(document);

    router.push({
      name: 'consultationPdfGed',
      params: { id: data.id },
    });
  }

  function getSortedListeDocumentGed() {
    if (lazyParamsDatatableGed.value.sortField && lazyParamsDatatableGed.value.sortOrder)
      return cloneDeep(
        listeDocumentGed.value.sort(
          dynamicSort(lazyParamsDatatableGed.value.sortField, lazyParamsDatatableGed.value.sortOrder ?? 1)
        )
      );

    return listeDocumentGed.value;
  }

  async function pushToPreviousDocument(idDoc: string) {
    //Récupération de la liste affichée dans zoneDataTableGed avec le tri en local si c'est le cas
    const documentsGed = getSortedListeDocumentGed();
    const indexDocument = documentsGed.findIndex((d) => d.id === idDoc);
    if (indexDocument != -1) {
      let documentPrecedent: DocumentGed;
      if (indexDocument === 0 && rechercheEnCours.value.first !== 0) {
        const nouvelleRecherche = rechercheEnCours.value;
        nouvelleRecherche.first = nouvelleRecherche.first! - nouvelleRecherche.count!;

        await fetchAndStoreSearchFichier(nouvelleRecherche);

        documentPrecedent = listeDocumentGed.value[listeDocumentGed.value.length - 2];
      } else {
        documentPrecedent = documentsGed[indexDocument - 1];
      }
      if (documentPrecedent?.id)
        afficherPdf({ id: documentPrecedent.id, nom: documentPrecedent.nom } as GenericFileGed);
    }
  }

  async function pushToNextDocument(idDoc: string) {
    //Récupération de la liste affichée dans zoneDataTableGed avec le tri en local si c'est le cas
    const documentsGed = getSortedListeDocumentGed();
    const indexDocument = documentsGed.findIndex((d) => d.id === idDoc);

    if (indexDocument >= 0) {
      let documentSuivant: DocumentGed;
      const nbDocumentRestantPage = lazyParamsDatatableGed.value.rows - (indexDocument + 1);
      if (nbDocumentRestantPage === 0) {
        const nouvelleRecherche = rechercheEnCours.value;
        nouvelleRecherche.first = nouvelleRecherche.first! + nouvelleRecherche.count!;

        await fetchAndStoreSearchFichier(nouvelleRecherche);

        documentSuivant = listeDocumentGed.value[0];
      } else {
        documentSuivant = documentsGed[indexDocument + 1];
      }
      if (documentSuivant) afficherPdf({ id: documentSuivant.id, nom: documentSuivant.nom } as GenericFileGed);
    }
  }

  function previousDocumentIsAvailable(idDoc: string) {
    if (listeDocumentGed.value.length === 1) return false;
    const indexDoc = listeDocumentGed.value.findIndex((d) => d.id === idDoc);

    if (!isEmpty(rechercheEnCours.value) && (indexDoc !== 0 || rechercheEnCours.value.first !== 0)) return true;
    else return false;
  }

  function nextDocumentIsAvailable(idDoc: string) {
    if (listeDocumentGed.value.length === 1) return false;
    const indexDoc = listeDocumentGed.value.findIndex((d) => d.id === idDoc);
    const otherPagesIsAvailable = rechercheEnCours.value.first! + rechercheEnCours.value.count! < nbResultat.value;
    if (!isEmpty(rechercheEnCours.value) && (indexDoc !== listeDocumentGed.value.length - 1 || otherPagesIsAvailable))
      return true;
    else return false;
  }

  async function fetchAndStoreSearchFichier(recherche: RechercheGed) {
    await searchFichier(recherche);
    await storeRechercheEnCours(recherche);
    await storeLazyParamsDatatableGed({
      ...lazyParamsDatatableGed.value,
      first: recherche.first,
    } as EvenementLazyLoading);
  }

  async function ajouterThemeFilAriane(filAriane: MenuItem[], idTheme: number, query: LocationQueryRaw) {
    const theme = themesDocuments.value.find((td) => idTheme === td.theme_id)!;
    query.theme = idTheme;
    filAriane.push({ label: theme.libelle_theme, to: { name: 'Ged', query: cloneDeep(query) } } as MenuItem);
  }

  async function ajouterDossierFilAriane(filAriane: MenuItem[], typeDoc: string, query: LocationQueryRaw) {
    query.dossier = typeDoc;
    filAriane.push({ label: typeDoc, to: { name: 'Ged', query: cloneDeep(query) } } as MenuItem);
  }

  return {
    ajouterDocumentGed,
    donneesDocumentGed,
    searchFichier,
    fetchAllGed,
    fetchDocumentGed,
    fetchTypeDeDocumentGed,
    listeDocumentGed,
    listeGed,
    listeTypeDocumentGed,
    mettreLuDocument,
    telechargerDocumentGed,
    supprimerDocument,
    exploitationSelectionne,
    storeExploitationSelectionne,
    clearExploitationSelectionne,
    documentSelectionne,
    storeDocumentSelectionne,
    lazyParamsDatatableGed,
    storeLazyParamsDatatableGed,
    clearLazyParamsDatatableGed,
    themesDocuments,
    fetchThemesDocumentsByCheptel,
    navigationActiveGed,
    storeNavigationActiveGed,
    clearThemesDocuments,
    exploitationsSelectionnees,
    storeExploitationsSelectionnes,
    rechercheEnCours,
    storeRechercheEnCours,
    afficherPdf,
    pushToFile,
    nbResultat,
    fetchAndStoreSearchFichier,
    pushToPreviousDocument,
    pushToNextDocument,
    previousDocumentIsAvailable,
    nextDocumentIsAvailable,
    ajouterThemeFilAriane,
    ajouterDossierFilAriane,
  };
}
