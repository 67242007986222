import type { Cheptel, UtilisateurExploitation, TypeExploitation, Utilisateur } from '@/models';
import { ref } from 'vue';
import ExploitationState from './stateInterface';

export default {
  setExploitations(state: ExploitationState, payload: Cheptel[]) {
    state.exploitations = [];

    if (payload != null && payload != undefined) {
      payload.forEach((cheptel) => {
        cheptel.nom =
          cheptel.cheptel_hors_siecl != null
            ? cheptel.cheptel_hors_siecl.nom
            : cheptel.cheptel_periode_conduite!.detenteur.nom;

        cheptel.secteur =
          cheptel.cheptel_hors_siecl == null
            ? cheptel.contrat_cl!.num_secteur_cl
            : cheptel.cheptel_hors_siecl!.num_secteur;

        cheptel.commune =
          cheptel.cheptel_hors_siecl == null
            ? cheptel.cheptel_periode_conduite!.detenteur.ref_adresse_postale!.code_postal +
              ' ' +
              cheptel.cheptel_periode_conduite!.detenteur.ref_adresse_postale!.libelle
            : cheptel.ref_adresse_postale != null
            ? cheptel.ref_adresse_postale!.code_postal + ' ' + cheptel.ref_adresse_postale!.libelle
            : '';

        cheptel.num_detenteur =
          cheptel.cheptel_hors_siecl != null
            ? cheptel.cheptel_hors_siecl!.num_detenteur != null
              ? cheptel.cheptel_hors_siecl!.num_detenteur
              : ''
            : '';
        state.exploitations.push(cheptel);
      });
    }
  },

  setExploitationsSansHorsSiecl(state: ExploitationState, payload: Cheptel[]) {
    state.exploitationsSansHorsSiecl = [];

    if (payload != null && payload != undefined) {
      payload.forEach((cheptel) => {
        cheptel.nom = cheptel.cheptel_periode_conduite!.detenteur.nom;

        cheptel.secteur = cheptel.contrat_cl!.num_secteur_cl;

        cheptel.commune =
          cheptel.ref_adresse_postale != null
            ? cheptel.ref_adresse_postale!.code_postal + ' ' + cheptel.ref_adresse_postale!.libelle
            : '';
        state.exploitationsSansHorsSiecl.push(cheptel);
      });
    }
  },

  modifierUtilisateurAffectes(state: ExploitationState, payload: { utilisateurs: Utilisateur[]; id: number }) {
    const index = state.exploitations.findIndex((element) => element.id == payload.id);

    state.exploitations[index].utilisateur_exploitations = [];

    payload.utilisateurs.forEach((utilisateur) => {
      const utilExploit = ref<UtilisateurExploitation>({
        utilisateur: utilisateur,
      });

      state.exploitations[index].utilisateur_exploitations?.push(utilExploit.value);
    });
  },

  modifierCommentaire(state: ExploitationState, payload: { cheptelId: number; commentaire: string }) {
    const tmp = state.exploitations.findIndex((exploitation) => exploitation.id == payload.cheptelId);
    state.exploitations[tmp].commentaire = payload.commentaire;
  },

  setTypeExploitations(state: ExploitationState, payload: TypeExploitation[]) {
    state.type_exploitations = payload;
  },

  ajouterExploitation(state: ExploitationState, payload: Cheptel) {
    payload.nom = payload.cheptel_hors_siecl!.nom;
    payload.secteur = payload.cheptel_hors_siecl!.num_secteur;
    payload.commune = payload.ref_adresse_postale!.code_postal + ' ' + payload.ref_adresse_postale!.libelle;
    payload.num_detenteur = payload.cheptel_hors_siecl!.num_detenteur;

    state.exploitations.push(payload);
  },

  modifierExploitation(state: ExploitationState, payload: Cheptel) {
    const index = state.exploitations.findIndex((exploitation: Cheptel) => exploitation.id == payload.id);
    state.exploitations[index].nom = payload.cheptel_hors_siecl!.nom;
    state.exploitations[index].secteur = payload.cheptel_hors_siecl!.num_secteur;
    state.exploitations[index].commune =
      payload.ref_adresse_postale!.code_postal + ' ' + payload.ref_adresse_postale!.libelle;
    state.exploitations[index].num_detenteur = payload.cheptel_hors_siecl!.num_detenteur;
    state.exploitations[index].cheptel_hors_siecl = payload.cheptel_hors_siecl;
    state.exploitations[index].ref_type_exploitation = payload.ref_type_exploitation;
    state.exploitations[index].ref_pays = payload.ref_pays;
    state.exploitations[index].ref_adresse_postale = payload.ref_adresse_postale;
    state.exploitations[index].adresse1 = payload.adresse1;
    state.exploitations[index].adresse2 = payload.adresse2;
  },

  supprimerExploitationHorsSiecl(state: ExploitationState, payload: number) {
    state.exploitations = state.exploitations.filter((exploitation) => exploitation.id != payload);
  },
};
