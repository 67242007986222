<template>
  <Dialog header="Erreur" v-model:visible="visibiliteModal" :modal="true" :closable="false">
    <p class="m-0 flex align-items-center">
      <i class="pi pi-exclamation-circle mr-3" />
      <span v-if="libelleErreur">
        <pre>{{ libelleErreur }}</pre>
      </span>
      <span v-else>Une erreur s'est produite.</span>
    </p>
    <template #footer>
      <Button label="Fermer" @click="fermerModal" class="boutonFooter" />
    </template>
  </Dialog>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';

export default defineComponent({
  props: {
    afficherModal: Boolean,
    libelleErreur: String,
  },
  emits: ['update:afficherModal', 'update:libelleErreur'],
  setup(props, context) {
    const visibiliteModal = computed(() => {
      return props.afficherModal;
    });

    function fermerModal() {
      context.emit('update:afficherModal', false);
      context.emit('update:libelleErreur', '');
    }

    return {
      visibiliteModal,
      fermerModal,
    };
  },
});
</script>
