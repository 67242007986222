import DroitAPI from '@/api/DroitAPI';
import { ActionContext } from 'vuex';
import DroitState from './stateInterface';

export default {
  async fetchDroits(context: ActionContext<DroitState, any>) {
    const response = await DroitAPI.fetchDroits();
    if (response.status == 200 && response.data.body != null) {
      context.commit('setDroits', response.data.body);
    } else {
      throw new Error(response.data.message);
    }
  },
};
