import type { Alerte, AlerteTabDeBord } from '@/models';
import { ActionContext } from 'vuex';
import AlerteState from './stateInterface';
import AlerteAPI from '@/api/AlerteAPI';
import FunctionUtils from '@/utils/FunctionUtils';

const { throwError } = FunctionUtils();

export default {
  async fetchAlertesByOrganisme(
    context: ActionContext<AlerteState, any>,
    payload: { code_organisme: string; code_domaine?: string }
  ) {
    try {
      const response = await AlerteAPI.fetchAlertesByOrganisme(payload.code_organisme, payload.code_domaine);
      if (response.data.succeeded) {
        context.commit(
          'setAlertes',
          response.data.body.alertes.map((a: Alerte) => {
            return { ...a, code_organisme: response.data.body.code_organisme };
          })
        );
      } else {
        context.commit('setAlertes', []);
        throw new Error(response.data.message);
      }
    } catch (error: any) {
      throwError(error);
    }
  },
  async fetchAlerteByCodeAlerte(
    context: ActionContext<AlerteState, any>,
    payload: { code_organisme: string; code_domaine: string; code_alerte: string }
  ) {
    try {
      const response = await AlerteAPI.fetchAlertesByOrganisme(
        payload.code_organisme,
        payload.code_domaine,
        payload.code_alerte
      );
      if (response.data.succeeded) {
        context.commit('setAlerte', response.data.body.alertes[0]);
      } else {
        context.commit('setAlerte', {});
        throw new Error(response.data.message);
      }
    } catch (error: any) {
      throwError(error);
    }
  },
  async modifierAlerte(context: ActionContext<AlerteState, any>, payload: { alerte: Alerte }) {
    try {
      const response = await AlerteAPI.modifierAlerte(payload.alerte);
      if (response.data.succeeded) {
        context.commit('modifierAlerte', response.data.body);
      } else {
        throw new Error(response.data.message);
      }
    } catch (error: any) {
      throwError(error);
    }
  },
  async initialiserAlertesByOrganismeReference(
    context: ActionContext<AlerteState, any>,
    payload: { code_organisme: string; code_organisme_reference: string; code_domaine?: string; code_alerte?: string }
  ) {
    try {
      const response = await AlerteAPI.initialiserAlertesByOrganismeReference(payload);
      if (response.data.succeeded) {
        //context.commit('modifierAlerte', response.data.body);
      } else {
        throw new Error(response.data.message);
      }
    } catch (error: any) {
      throwError(error);
    }
  },
  async fetchAlertesTDB(
    context: ActionContext<AlerteState, any>,
    payload: { code_organisme: string; id_technicien: number }
  ) {
    try {
      const response = await AlerteAPI.fetchAlertesTDB(payload.code_organisme, payload.id_technicien);
      if (response.data.succeeded) {
        context.commit('setAlertesTDB', response.data.body);
      } else {
        throw new Error(response.data.message);
      }
    } catch (error: any) {
      throwError(error);
    }
  },
  async creerAlerteTDB(
    _: ActionContext<AlerteState, any>,
    payload: { alerteTDB: AlerteTabDeBord; code_organisme: string; id_technicien: number }
  ) {
    try {
      const response = await AlerteAPI.creerAlerteTDB(payload.alerteTDB, payload.code_organisme, payload.id_technicien);
      if (!response.data.succeeded) throw new Error(response.data.message);
    } catch (error: any) {
      throwError(error);
    }
  },
  async modifierAlerteTDB(
    _: ActionContext<AlerteState, any>,
    payload: { alerteTDB: AlerteTabDeBord; code_organisme: string; id_technicien: number }
  ) {
    try {
      const response = await AlerteAPI.modifierAlerteTDB(
        payload.alerteTDB,
        payload.code_organisme,
        payload.id_technicien
      );
      if (!response.data.succeeded) throw new Error(response.data.message);
    } catch (error: any) {
      throwError(error);
    }
  },
  async fetchCheptelsAlertes(
    context: ActionContext<AlerteState, any>,
    payload: { code_organisme: string; secteur: string; id_technicien: number }
  ) {
    try {
      const response = await AlerteAPI.fetchAlertesByTechnicien(
        payload.code_organisme,
        payload.secteur,
        payload.id_technicien
      );
      if (response.data.succeeded) {
        context.commit('setCheptelsAlertes', response.data.body);
      } else {
        throw new Error(response.data.message);
      }
    } catch (error: any) {
      throwError(error);
    }
  },
  async calculerAlertes(
    _: ActionContext<AlerteState, any>,
    payload: { code_organisme: string; numero_exploitation?: string }
  ) {
    try {
      const response = await AlerteAPI.calculerAlertes(payload);
      if (!response.data.succeeded) {
        throw new Error(response.data.body);
      }
    } catch (error: any) {
      throwError(error);
    }
  },
};
