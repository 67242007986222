import axios, { AxiosResponse } from 'axios';
import { ApiUrlAlerte } from '@/constants/ApiUrls';
import { Alerte, AlerteTabDeBord, ResponseModel } from '@/models';
import { apiConfig } from '@/config/UtilisateurConfig';

export default {
  async fetchAlertesByOrganisme(
    code_organisme: string,
    code_domaine?: string,
    code_alerte?: string
  ): Promise<AxiosResponse<ResponseModel>> {
    const url = new URL(`${ApiUrlAlerte}/seuils`);

    const queryParameters: { codeOrganisme: string; codeDomaine?: string; codeAlerte?: string } = {
      codeOrganisme: code_organisme,
    };
    if (code_domaine) queryParameters.codeDomaine = code_domaine;
    if (code_alerte) queryParameters.codeAlerte = code_alerte;

    url.search = new URLSearchParams(queryParameters).toString();
    return axios.get(`${url}`, apiConfig.value);
  },
  async modifierAlerte(alerte: Alerte): Promise<AxiosResponse<ResponseModel>> {
    const putAlerteObject = { ...alerte, id_alerte: alerte.id };
    return await axios.put(`${ApiUrlAlerte}/seuils`, putAlerteObject, apiConfig.value);
  },
  async initialiserAlertesByOrganismeReference(payload: {
    code_organisme: string;
    code_organisme_reference: string;
    code_domaine?: string;
    code_alerte?: string;
  }): Promise<AxiosResponse<ResponseModel>> {
    return await axios.put(`${ApiUrlAlerte}/seuils/organisme`, payload, apiConfig.value);
  },
  async fetchAlertesByTechnicien(
    code_organisme: string,
    secteur: string,
    id_technicien: number
  ): Promise<AxiosResponse<ResponseModel>> {
    const url = new URL(`${ApiUrlAlerte}`);

    const queryParameters: { codeOrganisme: string; secteur: string; idTechnicien: string } = {
      codeOrganisme: code_organisme,
      secteur: secteur,
      idTechnicien: id_technicien.toString(),
    };

    url.search = new URLSearchParams(queryParameters).toString();
    return axios.get(`${url}`, apiConfig.value);
  },
  async fetchAlertesTDB(code_organisme: string, id_technicien: number): Promise<AxiosResponse<ResponseModel>> {
    const url = new URL(`${ApiUrlAlerte}/tableauBord`);

    const queryParameters: { codeOrganisme: string; idTechnicien: string } = {
      codeOrganisme: code_organisme,
      idTechnicien: id_technicien.toString(),
    };

    url.search = new URLSearchParams(queryParameters).toString();
    return axios.get(`${url}`, apiConfig.value);
  },
  async creerAlerteTDB(
    alerteTDB: AlerteTabDeBord,
    code_organisme: string,
    id_technicien: number
  ): Promise<AxiosResponse<ResponseModel>> {
    const postAlerteTDB = { ...alerteTDB, code_organisme: code_organisme, technicien_id: id_technicien };
    return await axios.post(`${ApiUrlAlerte}/tableauBord`, postAlerteTDB, apiConfig.value);
  },
  async modifierAlerteTDB(
    alerteTDB: AlerteTabDeBord,
    code_organisme: string,
    id_technicien: number
  ): Promise<AxiosResponse<ResponseModel>> {
    const putAlerteTDB = { ...alerteTDB, code_organisme: code_organisme, technicien_id: id_technicien };
    return await axios.put(`${ApiUrlAlerte}/tableauBord`, putAlerteTDB, apiConfig.value);
  },
  async calculerAlertes(payload: {
    code_organisme: string;
    numero_exploitation?: string;
  }): Promise<AxiosResponse<ResponseModel>> {
    return await axios.post(`${ApiUrlAlerte}/calcul`, payload, apiConfig.value);
  },
};
