import ProfilAPI from '@/api/ProfilAPI';
import { Profil } from '@/models';
import { ActionContext } from 'vuex';
import ProfilState from './stateInterface';

export default {
  async fetchProfilsFromOrganisme(context: ActionContext<ProfilState, any>, payload: { code_organisme: string }) {
    const response = await ProfilAPI.fetchProfilsFromOrganisme(payload.code_organisme);
    if (response.data.succeeded) {
      context.commit('setProfilsOrganisme', response.data.body);
    } else {
      throw new Error(response.data.message);
    }
  },

  async fetchProfilsFromAllOrganisme(context: ActionContext<ProfilState, any>) {
    const response = await ProfilAPI.fetchProfilsFromAllOrganisme();
    if (response.data.succeeded) context.commit('setAllProfilsOrganisme', response.data.body);
    else {
      throw new Error(response.data.message);
    }
  },

  async supprimerProfil(
    context: ActionContext<ProfilState, any>,
    payload: { ref_organisme_id: number; profil_id: number }
  ) {
    const response = await ProfilAPI.supprimerProfil(payload.profil_id);
    if (response.data.succeeded)
      context.commit('supprimerProfil', { profil_id: payload.profil_id, ref_organisme_id: payload.ref_organisme_id });
    else {
      throw new Error(response.data.message);
    }
  },

  async creerProfil(context: ActionContext<ProfilState, any>, payload: { profil: Profil }) {
    const response = await ProfilAPI.creerProfil(payload.profil);
    if (response.data.succeeded) context.commit('creerProfil', response.data.body);
    else {
      throw new Error(response.data.message);
    }
  },

  async modifierProfil(context: ActionContext<ProfilState, any>, payload: { profil_envoye: Profil; profil: Profil }) {
    const response = await ProfilAPI.modifierProfil(payload.profil_envoye);
    if (response.data.succeeded) context.commit('modifierProfil', payload.profil);
    else {
      throw new Error(response.data.message);
    }
  },
};
