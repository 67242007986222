import axios, { AxiosResponse } from 'axios';
import { ApiUrlOrganisme } from '@/constants/ApiUrls';
import type { ResponseModel } from '@/models';
import { apiConfig } from '@/config/UtilisateurConfig';

export default {
  async fetchAllOrganismes(): Promise<AxiosResponse<ResponseModel>> {
    return axios.get(`${ApiUrlOrganisme}/profils`, apiConfig.value);
  },
};
