import ExploitationState from './stateInterface';

export default {
  getAllExploitations(state: ExploitationState) {
    return state.exploitations;
  },

  getAllExploitationsSansHorsSiecl(state: ExploitationState) {
    return state.exploitationsSansHorsSiecl;
  },

  getTypeExploitations(state: ExploitationState) {
    return state.type_exploitations;
  },
};
