import type {
  TypeDocumentGed,
  DocumentGed,
  Cheptel,
  ThemeDocument,
  Ged,
  RechercheGed,
  EvenementLazyLoading,
} from '@/models';
import { MenuItem } from 'primevue/menuitem';
import { ref } from 'vue';
import GedState from './stateInterface';
import DateUtils from '@/utils/DateUtils';

const { parseStringDatesToJavascriptDates } = DateUtils();

export default {
  setAllGed(state: GedState, payload: any) {
    const geds: Ged[] = payload[0].facet_results;
    state.listeGed = geds;
  },

  setAllDocByType(state: GedState, payload: any) {
    const listeDocumentGed = [];

    if (payload.doc != null) {
      for (const element of payload.doc) {
        const documentGed = ref<DocumentGed>({
          id: '',
          nom: '',
          type: '',
          lu: false,
        } as DocumentGed);
        for (const metadata of element.metadata) {
          if (metadata.key == 'nomfichier') {
            documentGed.value.nom = metadata.value;
          } else if (metadata.key == 'typedoc') {
            documentGed.value.type = metadata.value;
          } else if (metadata.key == 'dateindexation') {
            documentGed.value.date = metadata.value;
          } else if (metadata.key == 'nuged') {
            documentGed.value.id = metadata.value;
          } else if (metadata.key == 'destlu') {
            documentGed.value.lu = metadata.value == 0 ? false : true;
          }
        }

        listeDocumentGed.push(documentGed.value);
      }
    }
    state.nbResultat = payload.count;
    parseStringDatesToJavascriptDates(listeDocumentGed, ['date']);
    state.listeDocumentGed = listeDocumentGed;
  },

  setDocumentGed(state: GedState, payload: any) {
    state.donneesDocumentGed = payload;
  },

  setTypeDocumentGed(state: GedState, payload: TypeDocumentGed[]) {
    state.listeTypeDocumentGed = payload;
  },

  supprimerDocument(state: GedState, payload: string) {
    state.listeDocumentGed = state.listeDocumentGed.filter((element) => element.id != payload);
  },

  mettreDocumentLu(state: GedState, payload: string) {
    const index = state.listeDocumentGed.findIndex((element) => element.id === payload);
    if (index !== -1) state.listeDocumentGed[index].lu = true;
  },

  setExploitationSelectionne(state: GedState, payload: Cheptel) {
    state.exploitationSelectionne = payload;
  },

  setDocumentSelectionne(state: GedState, payload: DocumentGed) {
    state.documentSelectionne = payload;
  },

  setThemesDocuments(state: GedState, payload: ThemeDocument[]) {
    parseStringDatesToJavascriptDates(payload, ['date_dernier_apport']);
    payload.forEach((theme) => {
      parseStringDatesToJavascriptDates(theme.types_documents, ['date_dernier_apport']);
      theme.types_documents = theme.types_documents.sort((a, b) => (a.num_ordre ?? 1) - (b.num_ordre ?? 2));
    });

    state.themesDocuments = payload.sort((a, b) => a.num_ordre_theme - b.num_ordre_theme);
  },

  setNavigationActiveGed(state: GedState, payload: MenuItem[]) {
    state.navigationActiveGed = payload;
  },

  setExploitationsSelectionnes(state: GedState, payload: Cheptel[]) {
    state.exploitationsSelectionnees = payload;
  },

  setNbResultat(state: GedState, payload: number) {
    state.nbResultat = payload;
  },

  setRechercheEnCours(state: GedState, payload: RechercheGed) {
    state.rechercheEnCours = payload;
  },

  setLazyParamsDatatableGed(state: GedState, payload: EvenementLazyLoading) {
    state.lazyParamsDatatableGed = payload;
  },
};
