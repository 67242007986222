import { ActionContext } from 'vuex';
import PaysState from './stateInterface';
import PaysAPI from '@/api/PaysAPI';

export default {
  async fetchAllPays(context: ActionContext<PaysState, any>) {
    const response = await PaysAPI.fetchPays();
    if (response.data.succeeded) {
      context.commit('setPays', response.data.body);
    } else {
      throw new Error(response.data.message);
    }
  },
};
