import type { ResponseError } from '@/models';
import axios, { AxiosError } from 'axios';

export default function FunctionUtils() {
  async function executeFunctionAndHandleErrors(fn: Function, emit: any) {
    try {
      await fn();
    } catch (e: any) {
      if (e.response && e.response.status === 500) {
        emit('update:afficherModalErreur', true);
      } else {
        emit('update:libelleErreur', e.message);
        emit('update:afficherModalErreur', true);
      }
    }
  }

  function throwError(error: any) {
    let message: string | undefined;
    if (axios.isAxiosError(error)) {
      const serverError = error as AxiosError<ResponseError>;
      message = serverError.response?.data.message;
      serverError.response?.data.errors.forEach((e: string) => {
        message += '\n' + e;
      });
    } else {
      message = error.message;
    }

    throw new Error(message);
  }

  function dynamicSort(property: string, sortOrder?: number) {
    return function (a: any, b: any) {
      const result = a[property].localeCompare(b[property], undefined, { numeric: true, sensitivity: 'base' });
      return result * (sortOrder ?? 1);
    };
  }

  return {
    executeFunctionAndHandleErrors,
    throwError,
    dynamicSort,
  };
}
