import { ActionContext } from 'vuex';
import UtilisateurAPI from '@/api/UtilisateurAPI';
import UtilisateurState from './stateInterface';
import { Organisme, ProfilUtilisateur, Utilisateur } from '@/models';

export default {
  async fetchUtilisateur(context: ActionContext<UtilisateurState, any>, payload: { username: string }) {
    const response = await UtilisateurAPI.fetchUtilisateur(payload.username);
    if (response.data.succeeded) {
      context.commit('setUtilisateur', response.data.body);
      localStorage.setItem('utilisateur', JSON.stringify(response.data.body));
    } else {
      context.commit('setUtilisateur', []);
      localStorage.setItem('utilisateur', JSON.stringify([]));
      throw new Error(response.data.message != null ? response.data.message : 'Identifiant ou mot de passe invalide');
    }
  },

  async fetchProfilsFromUtilisateurs(
    context: ActionContext<UtilisateurState, any>,
    payload: { code_organisme: string | undefined }
  ) {
    const response = await UtilisateurAPI.fetchProfilsFromUtilisateurs(payload.code_organisme);
    if (response.data.succeeded) {
      context.commit('setUtilisateurProfils', response.data.body);
    } else {
      throw new Error(response.data.message);
    }
  },

  async storeOrganismeConnecte(
    context: ActionContext<UtilisateurState, any>,
    payload: { organismeSelectionne: Organisme }
  ) {
    context.commit('setOrganismeConnecte', payload.organismeSelectionne);
  },

  async ajouterUtilisateur(context: ActionContext<UtilisateurState, any>, payload: { utilisateur: Utilisateur }) {
    const response = await UtilisateurAPI.ajouterUtilisateur(payload.utilisateur);
    if (response.data.succeeded) {
      context.commit('ajouterUtilisateurProfils', response.data.body);
    } else {
      throw new Error(response.data.message);
    }
  },

  async modifierUtilisateur(
    context: ActionContext<UtilisateurState, any>,
    payload: { utilisateurEnvoye: Utilisateur; profil: ProfilUtilisateur }
  ) {
    const response = await UtilisateurAPI.modifierUtilisateur(payload.utilisateurEnvoye);
    if (response.data.succeeded) {
      if (payload.utilisateurEnvoye.profils_utilisateurs!.length == 1) {
        context.commit('modifierUtilisateur', {
          profil: payload.profil,
          utilisateur: payload.utilisateurEnvoye,
          id: response.data.body.profil_utilisateur_modifie,
        });
      }
    } else {
      throw new Error(response.data.message);
    }
  },

  async modifierMotDePasse(context: ActionContext<UtilisateurState, any>, payload: { login: String; mdp: String }) {
    const response = await UtilisateurAPI.modifierMotDePasse(payload.login, payload.mdp);
    if (response.data.succeeded == false) {
      throw new Error(response.data.message);
    }
  },

  async supprimerUtilisateurProfil(context: ActionContext<UtilisateurState, any>, payload: { id: number }) {
    const response = await UtilisateurAPI.supprimerUtilisateur(payload.id);
    if (response.data.succeeded) {
      context.commit('supprimerUtilisateurProfil', payload.id);
    } else {
      throw new Error(response.data.message);
    }
  },

  async fetchAllUtilisateur(context: ActionContext<UtilisateurState, any>, payload: { code_organisme: string }) {
    const response = await UtilisateurAPI.fetchAllUtilisateur(payload.code_organisme);
    if (response.data.succeeded) {
      context.commit('setUtilisateurs', response.data.body);
    } else {
      throw new Error(response.data.message);
    }
  },

  async storeToken(context: ActionContext<UtilisateurState, any>, payload: { token: string }) {
    context.commit('setToken', payload.token);
  },

  async storeKeycloak(context: ActionContext<UtilisateurState, any>, payload: { keycloak: any }) {
    context.commit('setKeycloak', payload.keycloak);
  },

  async storeUsername(context: ActionContext<UtilisateurState, any>, payload: { username: string }) {
    context.commit('setUsername', payload.username);
  },

  async storeUtilisateurConnecte(context: ActionContext<UtilisateurState, any>, payload: { utilisateur: Utilisateur }) {
    context.commit('setUtilisateur', payload.utilisateur);
  },

  async storeFiltreUtilisateur(context: ActionContext<UtilisateurState, any>, payload: { filtre_utilisateur: {} }) {
    context.commit('setFiltreUtilisateur', payload.filtre_utilisateur);
    localStorage.setItem('filtre_utilisateur', JSON.stringify(payload.filtre_utilisateur));
  },
  async storePathNameOrigine(context: ActionContext<UtilisateurState, any>, payload: { path_name_origine: string }) {
    context.commit('setPathNameOrigine', payload.path_name_origine);
  },
};
