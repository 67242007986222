import AdressePostaleAPI from '@/api/AdressePostaleAPI';
import { ActionContext } from 'vuex';
import RaceState from './stateInterface';

export default {
  async fetchAllAdressesPostales(context: ActionContext<RaceState, any>) {
    const response = await AdressePostaleAPI.fetchAdressePostales();
    if (response.data.succeeded) {
      context.commit('setAdressesPostales', response.data.body);
    } else {
      throw new Error(response.data.message);
    }
  },
};
