import UtilisateurState from './stateInterface';

export default {
  getUtilisateur(state: UtilisateurState) {
    return state.utilisateur;
  },

  getAllUtilisateurs(state: UtilisateurState) {
    return state.utilisateurs;
  },

  getProfilUtilisateurs(state: UtilisateurState) {
    return state.profilUtilisateurs;
  },

  getDroitUtilisateur(state: UtilisateurState) {
    //liste de ref de droit regroupant ceux du profil et ceux de l'utilisateur
    return state.droits;
  },

  getToken(state: UtilisateurState) {
    return state.token;
  },

  getKeycloak(state: UtilisateurState) {
    return state.keycloak;
  },

  getFiltreUtilisateur(state: UtilisateurState) {
    if (state.contexte_utilisateur.filtre_utilisateur == undefined) {
      if (localStorage.getItem('filtre_utilisateur') != null)
        state.contexte_utilisateur.filtre_utilisateur = JSON.parse(localStorage.getItem('filtre_utilisateur')!);
    }
    return state.contexte_utilisateur.filtre_utilisateur;
  },
  getRoutesActives(state: UtilisateurState) {
    return state.routesActives;
  },
  getPathNameOrigine(state: UtilisateurState) {
    return state.pathNameOrigine;
  },
  getOrganismeConnecte(state: UtilisateurState) {
    return state.organismeConnecte;
  },
};
