import axios, { AxiosResponse } from 'axios';
import { ApiUrlPays } from '@/constants/ApiUrls';
import { ResponseModel } from '@/models';
import { apiConfig } from '@/config/UtilisateurConfig';

export default {
  async fetchPays(): Promise<AxiosResponse<ResponseModel>> {
    return axios.get(`${ApiUrlPays}`, apiConfig.value);
  },
};
