import axios, { AxiosResponse } from 'axios';
import { ApiUrlExploitation } from '@/constants/ApiUrls';
import { Cheptel, ResponseModel, UtilisateurExploitation } from '@/models';
import { apiConfig, apiConfigSansHeader } from '@/config/UtilisateurConfig';

export default {
  async fetchExploitations(): Promise<AxiosResponse<ResponseModel>> {
    return axios.get(`${ApiUrlExploitation}`, apiConfig.value);
  },

  async fetchAllExploitationsFromOrganisme(
    code_organisme: string,
    secteur: string,
    id_utilisateur: number
  ): Promise<AxiosResponse<ResponseModel>> {
    return axios.get(`${ApiUrlExploitation}/organisme/secteur/utilisateur`, {
      params: { codeOrg: code_organisme, secteur: secteur, id: id_utilisateur },
      headers: apiConfigSansHeader.value,
    });
  },

  async fetchAllExploitationsFromOrganismeSansHorsSiecl(
    code_organisme: string,
    secteur: string,
    id_utilisateur: number
  ): Promise<AxiosResponse<ResponseModel>> {
    return axios.get(`${ApiUrlExploitation}/organisme/secteur/utilisateur/siecl`, {
      params: { codeOrg: code_organisme, secteur: secteur, id: id_utilisateur },
      headers: apiConfigSansHeader.value,
    });
  },

  async fetchTypeExploitations(): Promise<AxiosResponse<ResponseModel>> {
    return axios.get(`${ApiUrlExploitation}/typeexploitations`, apiConfig.value);
  },

  async ajouterExploitation(exploitation: Cheptel): Promise<AxiosResponse<ResponseModel>> {
    return await axios.post(`${ApiUrlExploitation}/horssiecl`, exploitation, apiConfig.value);
  },

  async modifierExploitation(exploitation: Cheptel): Promise<AxiosResponse<ResponseModel>> {
    return await axios.put(`${ApiUrlExploitation}/horssiecl`, exploitation, apiConfig.value);
  },

  async supprimerExploitationHorsSiecl(id: number): Promise<AxiosResponse<ResponseModel>> {
    return axios.delete(`${ApiUrlExploitation}`, {
      params: { id: id },
      headers: apiConfigSansHeader.value,
    });
  },

  async supprimerUtilisateursAffectes(id: number): Promise<AxiosResponse<ResponseModel>> {
    return axios.delete(`${ApiUrlExploitation}/utilisateur`, {
      params: { id: id },
      headers: apiConfigSansHeader.value,
    });
  },

  async modifierUtilisateurAffectes(
    utilisateursAffectes: UtilisateurExploitation[]
  ): Promise<AxiosResponse<ResponseModel>> {
    return await axios.put(`${ApiUrlExploitation}/utilisateur`, utilisateursAffectes, apiConfig.value);
  },

  async modifierCommentaire(idCheptel: number, commentaire: String): Promise<AxiosResponse<ResponseModel>> {
    const objet = {
      cheptelId: idCheptel,
      commentaire: commentaire,
    };

    return await axios.put(`${ApiUrlExploitation}/commentaire`, objet, apiConfig.value);
  },

  async fetchExploitationsDetenteur(num_detenteur: string) {
    return axios.get(`${ApiUrlExploitation}/detenteur`, {
      params: { numDetenteurPersonne: num_detenteur },
      headers: apiConfigSansHeader.value,
    });
  },

  async fetchNumeroDetenteur(num_exploitation: string) {
    return axios.get(`${ApiUrlExploitation}/numerodetenteur`, {
      params: { numExploitation: num_exploitation },
      headers: apiConfigSansHeader.value,
    });
  },
};
