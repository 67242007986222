import axios, { AxiosResponse } from 'axios';
import { ApiUrlOrganisme, ApiUrlProfil } from '@/constants/ApiUrls';
import { Profil, ResponseModel } from '@/models';
import { apiConfig, apiConfigSansHeader } from '@/config/UtilisateurConfig';

export default {
  async fetchProfilsFromOrganisme(code_organisme: string): Promise<AxiosResponse<ResponseModel>> {
    return await axios.get(`${ApiUrlProfil}/organisme`, {
      params: { codeOrg: code_organisme },
      headers: apiConfigSansHeader.value,
    });
  },

  async fetchProfilsFromAllOrganisme(): Promise<AxiosResponse<ResponseModel>> {
    return await axios.get(`${ApiUrlOrganisme}/profils`, apiConfig.value);
  },

  async supprimerProfil(id_profil: number): Promise<AxiosResponse<ResponseModel>> {
    return await axios.delete(`${ApiUrlProfil}`, {
      params: { id: id_profil },
      headers: apiConfigSansHeader.value,
    });
  },

  async creerProfil(profil: Profil): Promise<AxiosResponse<ResponseModel>> {
    return await axios.post(`${ApiUrlProfil}`, profil, apiConfig.value);
  },

  async modifierProfil(profil: Profil): Promise<AxiosResponse<ResponseModel>> {
    return await axios.put(`${ApiUrlProfil}`, profil, apiConfig.value);
  },
};
