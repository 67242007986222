import mutations from './mutations';
import actions from './actions';
import getters from './getters';
import type {
  TypeDocumentGed,
  DocumentGed,
  Ged,
  Cheptel,
  ThemeDocument,
  RechercheGed,
  EvenementLazyLoading,
} from '@/models';
import GedModule from './stateInterface';
import { MenuItem } from 'primevue/menuitem';
import { getSessionStorage } from '@/store';

export default {
  namespaced: true,
  state(): GedModule {
    return {
      listeGed: [] as Ged[],
      listeDocumentGed: [] as DocumentGed[],
      listeTypeDocumentGed: [] as TypeDocumentGed[],
      donneesDocumentGed: '' as any,
      exploitationSelectionne:
        (getSessionStorage('GedModule', 'exploitationSelectionne') as Cheptel) || ({} as Cheptel),
      documentSelectionne: {} as DocumentGed,
      themesDocuments: [] as ThemeDocument[],
      navigationActiveGed: [] as MenuItem[],
      exploitationsSelectionnees: [] as Cheptel[],
      nbResultat: 0,
      rechercheEnCours: {} as RechercheGed,
      lazyParamsDatatableGed: {} as EvenementLazyLoading,
    };
  },
  mutations,
  actions,
  getters,
};
