import store from '@/store';
import { computed } from 'vue';
import { createRouter, createWebHistory, NavigationGuardNext, RouteRecordRaw } from 'vue-router';
import type { Utilisateur } from '@/models';
import Config from '../config.json';

const utilisateur = computed<Utilisateur>(() => {
  return store.getters['UtilisateurModule/getUtilisateur'];
});
const organismeConnecte = computed<Utilisateur>(() => {
  return store.getters['UtilisateurModule/getOrganismeConnecte'];
});

function redirectWithErrorToast(next: NavigationGuardNext) {
  next({ name: 'Accueil', query: { notAuthorized: 'true' } });
}

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/accueil',
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/accueil',
  },
  {
    path: '/accueil',
    name: 'Accueil',
    component: () => import('../views/Accueil.vue'),
  },
  {
    path: '/bttc',
    name: 'Bttc',
    component: () => import('../views/BTTC/AccueilBttc.vue'),
    beforeEnter: (to, from, next) => {
      if (utilisateur.value.consultationBttc) next();
      else redirectWithErrorToast(next);
    },
  },
  {
    path: '/bttcdetail/:id',
    name: 'BttcDetail',
    component: () => import('../views/BTTC/BttcDetail.vue'),
    props: true,
    beforeEnter: (to, from, next) => {
      if (utilisateur.value.consultationBttc) next();
      else redirectWithErrorToast(next);
    },
  },
  {
    path: '/calculbttc',
    name: 'CalculBttc',
    component: () => import('../views/BTTC/CalculBttc.vue'),
    beforeEnter: (to, from, next) => {
      if (utilisateur.value.calculBttc) next();
      else redirectWithErrorToast(next);
    },
  },
  {
    path: '/cheptel/:id',
    name: 'DetailExploitation',
    component: () => import('../views/DetailExploitation.vue'),
    props: true,
    beforeEnter: (to, from, next) => {
      if (utilisateur.value.consultationAffectation) next();
      else redirectWithErrorToast(next);
    },
  },
  {
    path: '/documents/consultationPdf/:id',
    name: 'consultationPdfGed',
    component: () => import('../views/GED/PdfViewer.vue'),
    props: true,
    beforeEnter: (to, from, next) => {
      if (utilisateur.value.consultationDocument) next();
      else redirectWithErrorToast(next);
    },
  },
  {
    path: '/documents',
    name: 'Ged',
    component: () => import('../views/GED/AccueilGed.vue'),
    beforeEnter: (to, from, next) => {
      if (utilisateur.value.consultationDocument) next();
      else redirectWithErrorToast(next);
    },
  },
  {
    path: '/importGed',
    name: 'ImportGed',
    component: () => import('../views/GED/ImportGed.vue'),
    beforeEnter: (to, from, next) => {
      if (utilisateur.value.gestionDocument) next();
      else redirectWithErrorToast(next);
    },
  },
  {
    path: '/listeCheptelGed',
    name: 'ListeCheptelGed',
    component: () => import('../views/GED/ListeCheptelGed.vue'),
    beforeEnter: (to, from, next) => {
      if (utilisateur.value.consultationDocument) next();
      else redirectWithErrorToast(next);
    },
  },
  {
    path: '/parametrage',
    name: 'Parametrage',
    component: () => import('../views/Parametrage/Parametrage.vue'),
  },
  {
    path: '/parametrage/affectation',
    name: 'Affectation exploitation',
    component: () => import('../views/Parametrage/ParametrageAffectation.vue'),
    beforeEnter: (to, from, next) => {
      if (utilisateur.value.consultationAffectation) next();
      else redirectWithErrorToast(next);
    },
  },
  {
    path: '/parametrage/profils',
    name: 'Profils',
    component: () => import('../views/Parametrage/ParametrageProfils.vue'),
    beforeEnter: (to, from, next) => {
      if (utilisateur.value.consultationProfil) next();
      else redirectWithErrorToast(next);
    },
  },
  {
    path: '/parametrage/utilisateurs',
    name: 'Utilisateurs',
    component: () => import('../views/Parametrage/ParametrageUtilisateurs.vue'),
    beforeEnter: (to, from, next) => {
      if (utilisateur.value.consultationUtilisateur) next();
      else redirectWithErrorToast(next);
    },
  },
  {
    path: '/parametrage/seuilsAlerte',
    name: 'Seuils Alerte',
    component: () => import('../views/Parametrage/ParametrageSeuilsAlertes.vue'),
    beforeEnter: (to, from, next) => {
      if (utilisateur.value.consultationSeuilAlerte) next();
      else redirectWithErrorToast(next);
    },
  },
  {
    path: '/selectionEcel',
    name: 'SelectionEcel',
    component: () => import('../views/SelectionEcel.vue'),
  },
  {
    path: '/alertes',
    name: 'Alertes',
    component: () => import('../views/Alertes/AccueilAlerte.vue'),
    beforeEnter: (to, from, next) => {
      if (utilisateur.value.consultationAlerte) next();
      else redirectWithErrorToast(next);
    },
  },
  {
    path: '/cheptel/:id/alertes',
    name: 'DetailAlerte',
    component: () => import('../views/Alertes/DetailAlerte.vue'),
    props: true,
    beforeEnter: (to, from, next) => {
      if (utilisateur.value.consultationAlerte) next();
      else redirectWithErrorToast(next);
    },
  },
];

const router = createRouter({
  history: createWebHistory(Config.BASE_URL),
  routes,
});

router.beforeEach(async (to: any, from, next) => {
  if (to.path == '/selectionEcel') {
    next();
  } else if (!organismeConnecte.value?.id) {
    next({ path: '/selectionEcel' });
  } else {
    next();
  }
});

export default router;
