import axios, { AxiosResponse } from 'axios';
import { ApiUrlBttc } from '@/constants/ApiUrls';
import { Bttc, ResponseModel } from '@/models';
import { apiConfig, apiConfigSansHeader, apiConfigCSV, apiConfigPDF } from '@/config/UtilisateurConfig';

export default {
  async ajouterBttc(bttc: Bttc): Promise<AxiosResponse<ResponseModel>> {
    return axios.post(`${ApiUrlBttc}/`, bttc, apiConfig.value);
  },

  async cloturer(id: number): Promise<AxiosResponse<ResponseModel>> {
    const tmp = {
      bttcId: id,
    };
    return axios.put(`${ApiUrlBttc}/Validation/Cloture`, tmp, apiConfig.value);
  },

  async csv(id: number): Promise<AxiosResponse> {
    return axios.get(`${ApiUrlBttc}/Validation/CSV`, {
      params: { bttcId: id },
      headers: apiConfigCSV.value,
    });
  },

  async fetchBttc(id: number): Promise<AxiosResponse<ResponseModel>> {
    return axios.get(`${ApiUrlBttc}/`, {
      params: { bttcId: id },
      headers: apiConfigSansHeader.value,
    });
  },

  async fetchAllBttc(code_organisme: string): Promise<AxiosResponse<ResponseModel>> {
    return axios.get(`${ApiUrlBttc}/All`, {
      params: { organismeUtilisateur: code_organisme },
      headers: apiConfigSansHeader.value,
    });
  },

  async fetchFamilleTypologie(): Promise<AxiosResponse<ResponseModel>> {
    return axios.get(`${ApiUrlBttc}/RefFamilleTypologie/All`, apiConfig.value);
  },

  async fetchOrganismesAllBttc(): Promise<AxiosResponse<ResponseModel>> {
    return axios.get(`${ApiUrlBttc}/OrganismesBttc/All`, apiConfig.value);
  },

  async integrer(
    id: number,
    organisme_utilisateur: string,
    liste_resultat: number[]
  ): Promise<AxiosResponse<ResponseModel>> {
    const tmp = {
      organismeUtilisateur: organisme_utilisateur,
      bttcId: id,
      resultatIds: liste_resultat,
    };
    return axios.put(`${ApiUrlBttc}/Validation/IntegrationGed`, tmp, apiConfig.value);
  },

  async pdf(id: number, resultat_id: number): Promise<AxiosResponse> {
    return axios.get(`${ApiUrlBttc}/Validation/PDF`, {
      params: {
        bttcId: id,
        resultatId: resultat_id,
      },
      headers: apiConfigPDF.value,
      responseType: 'blob',
    });
  },

  async supprimerPDF(
    organisme_utilisateur: string,
    id: number,
    resultat_id: number
  ): Promise<AxiosResponse<ResponseModel>> {
    const tmp = {
      organismeUtilisateur: organisme_utilisateur,
      bttcId: id,
      resultatId: resultat_id,
    };
    return axios.put(`${ApiUrlBttc}/Validation/SuppressionPDF`, tmp, apiConfig.value);
  },

  async valider(valider: boolean, id: string): Promise<AxiosResponse<ResponseModel>> {
    const tmp = {
      bttcId: id,
      valide: valider,
    };
    return axios.put(`${ApiUrlBttc}/Validation`, tmp, apiConfig.value);
  },
};
