import type { Cheptel, TypeExploitation, Utilisateur, UtilisateurExploitation } from '@/models';
import { computed } from 'vue';
import { useStore } from 'vuex';

export default function ExploitationService() {
  const store = useStore();

  const all_exploitations = computed((): Cheptel[] => {
    return store.getters['ExploitationModule/getAllExploitations'];
  });

  const allExploitationsSansHorsSiecl = computed((): Cheptel[] => {
    return store.getters['ExploitationModule/getAllExploitationsSansHorsSiecl'];
  });

  const typeExploitations = computed((): TypeExploitation[] => {
    return store.getters['ExploitationModule/getTypeExploitations'];
  });

  async function fetchAllExploitations() {
    await store.dispatch('ExploitationModule/fetchAllExploitations');
  }

  async function fetchAllExploitationsFromOrganisme(code_organisme: string, secteur: string, id_utilisateur: number) {
    await store.dispatch('ExploitationModule/fetchAllExploitationsFromOrganisme', {
      code_organisme,
      secteur,
      id_utilisateur,
    });
  }

  async function fetchAllExploitationsFromOrganismeSansHorsSiecl(
    code_organisme: string,
    secteur: string,
    id_utilisateur: number
  ) {
    await store.dispatch('ExploitationModule/fetchAllExploitationsFromOrganismeSansHorsSiecl', {
      code_organisme,
      secteur,
      id_utilisateur,
    });
  }

  async function fetchAllTypeExploitations() {
    await store.dispatch('ExploitationModule/fetchAllTypeExploitations');
  }

  async function ajouterExploitation(exploitation: Cheptel) {
    await store.dispatch('ExploitationModule/ajouterExploitation', { exploitation });
  }

  async function modifierExploitation(exploitation: Cheptel) {
    await store.dispatch('ExploitationModule/modifierExploitation', { exploitation });
  }

  async function modifierUtilisateursAffectes(
    utilisateursAffectes: UtilisateurExploitation[],
    utilisateurs: Utilisateur[]
  ) {
    await store.dispatch('ExploitationModule/modifierUtilisateursAffectes', { utilisateursAffectes, utilisateurs });
  }

  async function modifierCommentaire(cheptelId: number, commentaire: string) {
    await store.dispatch('ExploitationModule/modifierCommentaire', { cheptelId, commentaire });
  }

  async function supprimerExploitationHorsSiecl(id: number) {
    await store.dispatch('ExploitationModule/supprimerExploitationHorsSiecl', { id });
  }

  async function supprimerUtilisateursAffectes(id: number) {
    await store.dispatch('ExploitationModule/supprimerUtilisateursAffectes', { id });
  }

  async function fetchExploitationsDetenteur(num_detenteur: string) {
    await store.dispatch('ExploitationModule/fetchExploitationsDetenteur', { num_detenteur });
  }

  async function fetchNumeroDetenteur(num_exploitation: string) {
    return await store.dispatch('ExploitationModule/fetchNumeroDetenteur', { num_exploitation });
  }

  return {
    ajouterExploitation,
    all_exploitations,
    allExploitationsSansHorsSiecl,
    fetchAllExploitations,
    fetchAllExploitationsFromOrganisme,
    fetchAllExploitationsFromOrganismeSansHorsSiecl,
    fetchAllTypeExploitations,
    fetchExploitationsDetenteur,
    fetchNumeroDetenteur,
    modifierCommentaire,
    modifierExploitation,
    modifierUtilisateursAffectes,
    supprimerExploitationHorsSiecl,
    supprimerUtilisateursAffectes,
    typeExploitations,
  };
}
