<template>
  <div v-if="!isEmpty(organismeConnecte)">
    <the-header></the-header>
    <div class="flex content">
      <div class="fixed-sidebar">
        <SidebarMenu />
      </div>
      <router-view class="container" />
    </div>
  </div>
  <div v-else>
    <div>
      <router-view />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue';
import TheHeader from '@/components/UI/TheHeader.vue';
import SidebarMenu from '@/components/UI/SidebarMenu.vue';
import UtilisateurService from '@/services/UtilisateurService';
import type { Organisme } from '@/models';
import { isEmpty } from 'lodash';

const { utilisateur, storePathNameOrigine, organismeConnecte, redirectionAccueilUtilisateur } = UtilisateurService();

const organismes = ref<Organisme[]>([]);

onMounted(async () => {
  await storePathNameOrigine(window.location.pathname + window.location.search);
  utilisateur.value.profils_utilisateurs!.forEach((profil) => organismes.value.push(profil.ref_organisme!));
  redirectionAccueilUtilisateur();
});
</script>

<style lang="scss" scoped>
@import '@/assets/style/scss/variables.scss';
.content {
  height: calc(100vh - 70px);
  max-height: calc(100vh - 70px);
}

.container {
  padding: 3rem;
  padding-top: 2rem;
  max-width: 100vw !important;
  width: 100vw;
  height: calc(100vh - 70px) !important;
  overflow-y: auto !important;
  overflow-x: hidden;
}

.fixed-sidebar {
  top: 0;
  left: 0;
  background-color: $tertiary-color;
  min-width: 18rem;
  width: 18rem;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
