<template>
  <div class="card">
    <ErrorDialog v-model:afficherModal="afficherModalErreur" v-model:libelleErreur="libelleErreur" />

    <Dialog header="Modification du compte" v-model:visible="visibleDialogEditCompte" :modal="true" :closable="true">
      <template> Mon compte </template>
      <div class="flex font-bold text-black border-round m-2">Nom</div>
      <div class="flex font-bold text-white border-round m-2">
        <InputText type="text" v-model="nom" style="width: 100%" />
      </div>

      <div class="flex font-bold text-black border-round m-2">Prénom</div>
      <div class="flex font-bold text-white border-round m-2">
        <InputText type="text" v-model="prenom" style="width: 100%" />
      </div>

      <div class="flex font-bold text-black border-round m-2">Email</div>
      <div class="flex font-bold text-white border-round m-2">
        <InputText type="text" v-model="email" style="width: 100%" />
      </div>

      <div class="flex font-bold text-black border-round m-2">Téléphone</div>
      <div class="flex font-bold text-white border-round m-2">
        <InputText type="text" v-model="telephone" style="width: 100%" />
      </div>

      <template #footer>
        <div class="flex justify-content-end flex-wrap card-container">
          <div class="flex align-items-center justify-content-center">
            <Button label="Annuler" @click="fermer" class="p-button-text" />
          </div>
          <div>
            <div class="flex align-items-center justify-content-center">
              <Button label="Modifier mot de passe" @click="modifierMdp = true" icon="pi pi-pencil" autofocus />
            </div>
          </div>
          <Button label="Enregistrer" @click="modifier" icon="pi pi-save" autofocus />
        </div>
      </template>
    </Dialog>

    <Dialog header="Modification du mot de passe" v-model:visible="modifierMdp" :modal="true">
      <div class="flex font-bold text-black border-round m-2">Nouveau mot de passe</div>
      <div class="flex font-bold text-white border-round m-2">
        <Password v-model="nouveauMdp" toggleMask style="width: 100%" :feedback="false" />
      </div>

      <div class="flex font-bold text-black border-round m-2">Confirmation du nouveau mot de passe</div>
      <div class="flex font-bold text-white border-round m-2">
        <Password v-model="confirmationNouveauMdp" toggleMask style="width: 100%" :feedback="false" />
      </div>
      <template #footer>
        <Button label="Annuler" icon="pi pi-times" @click="fermerMdp" class="p-button-text" />
        <Button label="Enregistrer" icon="pi pi-check" @click="valider" autofocus />
      </template>
    </Dialog>
    <div class="top-header">
      <div class="grid">
        <div class="col">
          <div class="flex align-items-center justify-content-start font-bold col-12 md:col-6 lg:col-6">
            <router-link to="/Accueil"
              ><img alt="Logo Cap Web" src="../../assets/LogoCapWeb.png" height="40"
            /></router-link>
          </div>
        </div>

        <div id="logoOrganismeConnecte" class="col flex align-items-center justify-content-center">
          <h3 style="display: inline">
            <div v-if="texteLogo == ''">
              {{ organismeConnecte?.nom }}
            </div>
          </h3>
          <div v-if="texteLogo != ''">
            <img :alt="alt" :src="icone" height="40" />
          </div>
        </div>
        <div class="col flex justify-content-end">
          <Button
            id="btnMenuUtilisateur"
            type="button"
            @click="toggleMenuUtilisateur"
            aria-haspopup="true"
            aria-controls="overlay_menu"
          >
            <Avatar id="avatarHeader" :label="labelAvatar" shape="circle" />
            {{ libelleBtnMenuUtilisateur }}
            <span class="material-icons ml-2"> expand_more </span>
          </Button>
          <div
            id="btnBurger"
            class="material-icons align-items-center mr-4 cursor-pointer"
            @click="afficherSidebar = true"
          >
            menu
          </div>
        </div>
      </div>
    </div>

    <Menu id="overlay_menu" ref="menuUtilisateur" class="w-15rem" :model="items" :popup="true">
      <template #item="{ item }">
        <div class="itemMenuUtilisateur" @click="item.command">
          <span class="material-icons">
            {{ item.icon }}
          </span>
          <div class="ml-2">{{ item.label }}</div>
        </div>
      </template>
    </Menu>
    <Sidebar v-model:visible="afficherSidebar" :baseZIndex="10000" position="left">
      <SidebarMenu></SidebarMenu>

      <div class="center">
        <Button
          id="btnCompteSidebar"
          type="button"
          @click="toggleMenuUtilisateur"
          aria-haspopup="true"
          aria-controls="overlay_menu"
        >
          <Avatar id="avatarHeader" :label="labelAvatar" shape="circle" />
          {{ libelleBtnMenuUtilisateur }}
          <span class="material-icons ml-2"> expand_more </span>
        </Button>
      </div>
    </Sidebar>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, ref, watch } from 'vue';
import SidebarMenu from '@/components/UI/SidebarMenu.vue';
import { useRoute } from 'vue-router';
import UtilisateurService from '@/services/UtilisateurService';
import { MenuItem } from 'primevue/menuitem';
import type { Utilisateur } from '@/models';

const route = useRoute();

const { utilisateur, changerOrganisme, modifierUtilisateur, modifierMotDePasse, keycloak, organismeConnecte } =
  UtilisateurService();

const items = ref<MenuItem[]>([]);
const menuUtilisateur = ref();
const libelleBtnMenuUtilisateur = ref('');
const labelAvatar = ref('');
const visibleDialogEditCompte = ref(false);
const afficherSidebar = ref(false);

const icone = ref('');
const alt = ref('');
const texteLogo = ref('');

const nom = ref();
const prenom = ref();
const email = ref();
const telephone = ref();

const modifierMdp = ref(false);
const nouveauMdp = ref('');
const confirmationNouveauMdp = ref('');

const libelleErreur = ref('');
const afficherModalErreur = ref(false);

async function modifier() {
  const ok = verifierChamps();

  if (ok) {
    const util = ref<Utilisateur>({
      id: utilisateur.value.id,
      email: email.value,
      nom: nom.value,
      prenom: prenom.value,
      num_telephone: telephone.value,
      droits_utilisateurs: [],
      profils_utilisateurs: [],
    });

    await modifierUtilisateur(util.value, undefined);

    visibleDialogEditCompte.value = false;
  }
}

async function valider() {
  if (nouveauMdp.value == '') {
    libelleErreur.value = 'Le mot de passe ne peut pas être vide';
    afficherModalErreur.value = true;
  } else if (nouveauMdp.value != confirmationNouveauMdp.value) {
    libelleErreur.value = 'Les mots de passe ne correspondent pas';
    afficherModalErreur.value = true;
  } else {
    await modifierMotDePasse(utilisateur.value.id_keycloak!, nouveauMdp.value);
    modifierMdp.value = false;
  }
}

function verifierChamps() {
  const regexEmail = new RegExp(
    /^[\w-]+(\.[\w-]+)*@([a-z0-9-]+(\.[a-z0-9-]+)*?\.[a-z]{2,6}|(\d{1,3}\.){3}\d{1,3})(:\d{4})?$/
  );

  const regexTelephone = new RegExp(/^0[1-9][0-9]{8}$/);

  if (!nom.value) {
    libelleErreur.value = 'Le nom est obligatoire';
    afficherModalErreur.value = true;
    return false;
  } else if (!prenom.value) {
    libelleErreur.value = 'Le prénom est obligatoire';
    afficherModalErreur.value = true;
    return false;
  } else if (!email.value) {
    libelleErreur.value = "L'adresse mail est obligatoire";
    afficherModalErreur.value = true;
    return false;
  } else if (email.value && !regexEmail.test(email.value as string)) {
    libelleErreur.value = "L'adresse mail est incorrecte";
    afficherModalErreur.value = true;
    return false;
  } else if (!telephone.value) {
    libelleErreur.value = 'Le numéro de téléphone est obligatoire';
    afficherModalErreur.value = true;
    return false;
  } else if (telephone.value != '' && !regexTelephone.test(telephone.value as string)) {
    libelleErreur.value = 'Le numéro de téléphone est incorrecte';
    afficherModalErreur.value = true;
    return false;
  }

  return true;
}

function fermerMdp() {
  modifierMdp.value = false;
}

function fermer() {
  visibleDialogEditCompte.value = false;
}

function setItems() {
  libelleBtnMenuUtilisateur.value = utilisateur.value.nom + ' ' + utilisateur.value.prenom;
  labelAvatar.value = utilisateur.value.nom.charAt(0) + utilisateur.value.prenom?.charAt(0);

  items.value = [
    {
      label: 'Mon compte',
      icon: 'edit',
      command: () => {
        visibleDialogEditCompte.value = true;
      },
    },
    {
      label: 'Déconnexion',
      icon: 'logout',
      command: () => {
        deconnexion();
      },
    },
  ];
  if (utilisateur.value.profils_utilisateurs && utilisateur.value.profils_utilisateurs?.length > 1) {
    items.value.unshift({
      label: 'Changement organisme',
      icon: 'refresh',
      command: () => {
        changerOrganisme();
      },
    });
  }
}

function deconnexion() {
  keycloak.value.logout();
  sessionStorage.clear();
}

function toggleMenuUtilisateur(event: MouseEvent) {
  menuUtilisateur.value.toggle(event);
}

watch(
  () => utilisateur.value.nom,
  () => {
    setItems();
  }
);

watch(
  () => utilisateur.value.prenom,
  () => {
    setItems();
  }
);
watch(
  () => route.fullPath,
  () => {
    afficherSidebar.value = false;
  }
);

onMounted(async () => {
  setItems();
  nom.value = utilisateur.value.nom;
  prenom.value = utilisateur.value.prenom;
  email.value = utilisateur.value.email;
  telephone.value = utilisateur.value.num_telephone;

  texteLogo.value =
    utilisateur.value.profils_utilisateurs!.filter(
      (profil) => profil.ref_organisme?.id == organismeConnecte.value?.id
    )[0].ref_organisme_icone_base64 == undefined
      ? ''
      : utilisateur.value.profils_utilisateurs!.filter(
          (profil) => profil.ref_organisme?.id == organismeConnecte.value?.id
        )[0].ref_organisme_icone_base64!;

  icone.value = 'data:image/png;base64,' + texteLogo.value;

  alt.value = 'Logo ' + organismeConnecte.value!.nom;
});
</script>
<style lang="scss" scoped>
@import '@/assets/style/scss/variables.scss';
::v-deep(.p-password input) {
  width: 100%;
}

.top-header {
  box-shadow: 0px 4px 12px rgb(18 105 154 / 10%);
  max-width: 100vw !important;
  height: 69px;
  padding: 0.5rem;
  background: #ffffff;
  color: #333333;
  border: 1px solid #c8c8c8;
  border-radius: 3px;
  position: relative;
  align-items: start;
}

#btnMenuUtilisateur {
  color: $text-color;
  background-color: #ffffff;
  border: none;
  font-size: 14px;
}

#btnMenuUtilisateur:focus {
  box-shadow: 0 0 0 0.2rem $tertiary-color-hover;
  border-radius: 3px;
}
#btnCompteSidebar {
  color: $text-color;
  background-color: $tertiary-color;
  border: none;
}
#btnCompteSidebar:focus {
  box-shadow: none;
}

.itemMenuUtilisateur {
  display: flex;
  justify-content: left;
  align-items: center;
  padding: 4px 4px 4px 11px;
  cursor: pointer;
}

.itemMenuUtilisateur:hover {
  background-color: $tertiary-color-hover;
}

::v-deep(.p-menu.p-menu-overlay) {
  border-radius: 5px;
}
</style>
