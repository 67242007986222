import { Organisme } from '@/models';
import OrganismeState from './stateInterface';

export default {
  setOrganismes(state: OrganismeState, payload: Organisme[]) {
    payload.forEach((organisme) => {
      organisme.codeEtNom = organisme.code + ' - ' + organisme.nom;
      organisme.codeEtNomSansCaractereSpeciaux = organisme.code + ' - ' + organisme.nom.replaceAll('.', '');
    });
    state.organismes = payload;
  },
};
