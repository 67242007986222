<template>
  <nav>
    <div :class="{ 'cursor-pointer': utilisateurHasMultipleProfiles }" @click="onChangerOrganisme()">
      <div class="encart-exploitation grid">
        <div class="col-10">
          <div class="titre">{{ isEleveur ? 'Exploitation ' : 'Organisme' }}</div>
          <div class="libelle-exploitation">
            <div v-if="isEleveur">
              <div>N°{{ profilUtilisateurConnecte?.num_exploitation }}</div>
              <div>{{ nomExploitation }}</div>
            </div>
            <div v-else>
              <div>{{ organismeConnecte?.code }}</div>
              <div>{{ organismeConnecte?.nom }}</div>
            </div>
          </div>
        </div>
        <div class="col-2">
          <span
            v-if="utilisateurHasMultipleProfiles"
            v-tooltip="`Changer d'organisme`"
            class="material-icons"
            style="font-size: 16px; margin-top: 8px"
          >
            arrow_forward_ios
          </span>
        </div>
      </div>
    </div>
    <div id="treeMenu">
      <Tree
        :value="nodes"
        selectionMode="multiple"
        v-model:selectionKeys="selectedKeys"
        v-model:expandedKeys="expandedKeys"
        @nodeSelect="onNodeSelect"
      >
        <template #Niveau1="slotProps">
          <a class="tab">
            <div class="niveau-1">
              <div class="icon" style="height: 24px">
                <span class="material-icons"> {{ slotProps.node.icon }} </span>
              </div>
              <div style="margin-top: 3px">{{ slotProps.node.label }}</div>
            </div>
          </a>
        </template>
        <template #Niveau2="slotProps">
          <div class="niveau-2">
            <div class="icon">
              <img
                :src="'data:image/svg+xml;base64,' + slotProps.node.icon"
                :alt="'Icone ' + slotProps.node.label"
                style="width: 16px"
              />
            </div>
            <span class="libelle">{{ slotProps.node.label }}</span>
          </div>
        </template>
        <template #Niveau3="slotProps">
          <div class="niveau-3">
            <div class="icon">
              <span class="material-icons" style="font-size: 16px"> {{ slotProps.node.icon }} </span>
            </div>
            <span class="libelle">{{ slotProps.node.label }}</span>
          </div>
        </template>
      </Tree>
    </div>
  </nav>
</template>
<script lang="ts" setup>
import { ref, onMounted, watch, computed } from 'vue';
import { RouteLocationRaw, useRouter } from 'vue-router';
import UtilisateurService from '@/services/UtilisateurService';
import GedService from '@/services/GedService';
import ExploitationService from '@/services/ExploitationService';
import { TreeNode, TreeSelectionKeys } from 'primevue/tree';

const router = useRouter();

const { utilisateur, changerOrganisme, isEleveur, profilUtilisateurConnecte, routesActives, organismeConnecte } =
  UtilisateurService();
const { themesDocuments } = GedService();
const { all_exploitations, fetchAllExploitationsFromOrganisme } = ExploitationService();

const droitParametrage =
  utilisateur.value.consultationAffectation ||
  utilisateur.value.gestionAffectation ||
  utilisateur.value.consultationUtilisateur ||
  utilisateur.value.gestionProfil ||
  utilisateur.value.consultationProfil ||
  utilisateur.value.gestionProfil;

const droitBttc = utilisateur.value.consultationBttc || utilisateur.value.calculBttc || utilisateur.value.gestionBttc;

const droitDocument = utilisateur.value.consultationDocument || utilisateur.value.gestionDocument;

const nodes = ref<TreeNode[]>([
  {
    key: '0',
    label: 'Accueil',
    data: { route: { name: 'Accueil' } },
    icon: 'home',
    type: 'Niveau1',
  },
]);
const selectedKeys = ref<TreeSelectionKeys>({});
const expandedKeys = ref<TreeSelectionKeys>({ '2': true });
const nomExploitation = ref('');

const utilisateurHasMultipleProfiles = computed(() => {
  return utilisateur.value.profils_utilisateurs && utilisateur.value.profils_utilisateurs?.length > 1;
});

watch(
  () => themesDocuments.value,
  () => {
    chargerSousMenuDocument();
  }
);

watch(
  () => routesActives.value,
  () => {
    selectedKeys.value = {};
    expandedKeys.value = { '2': true };
    selectionnerActiveKey(nodes.value);
  }
);

function selectionnerActiveKey(treeNodes: TreeNode[]) {
  routesActives.value.forEach((r) => {
    const node = treeNodes.find((n) => JSON.stringify(n.data.route) === JSON.stringify(r));
    if (node?.key) {
      selectedKeys.value[node?.key] = true;
      expandNode(node);
    }

    if (node?.children) selectionnerActiveKey(node?.children);
  });
}

function expandNode(node: TreeSelectionKeys) {
  expandedKeys.value[node.key] = true;
}

function onNodeSelect(node: TreeNode) {
  router.push(node.data.route);
}

async function onChangerOrganisme() {
  if (utilisateurHasMultipleProfiles.value) {
    await changerOrganisme();
  }
}
function chargerSousMenuDocument() {
  const sousMenu: TreeNode[] = themesDocuments.value.map((t) => {
    return {
      key: 'theme-' + t.theme_id,
      label: t.libelle_theme,
      data: { route: { name: 'Ged', query: { theme: t.theme_id } } },
      type: 'Niveau2',
      icon: t.icone,
      children: t.types_documents.map((td) => {
        return {
          key: 'typeDoc-' + td.id,
          label: td.libelle,
          data: { route: { name: 'Ged', query: { theme: t.theme_id, dossier: td.libelle } } },
          type: 'Niveau3',
          icon: 'folder',
        };
      }),
    };
  });

  if (nodes.value.find((m) => m.label === 'Documents'))
    nodes.value.find((m) => m.label === 'Documents')!.children = sousMenu;
}

onMounted(async () => {
  if (droitBttc)
    nodes.value.push({
      key: '1',
      label: 'BTTC',
      data: { route: { name: 'Bttc' } },
      icon: 'leaderboard',
      type: 'Niveau1',
    });

  if (droitDocument)
    nodes.value.push({
      key: '2',
      label: 'Documents',
      data: { route: { name: 'Ged' } },
      icon: 'source',
      type: 'Niveau1',
    });

  if (utilisateur.value.consultationAlerte) {
    let routeTEMP: RouteLocationRaw = { name: 'Alertes' };
    if (isEleveur.value) {
      routeTEMP = {
        name: 'DetailAlerte',
        params: { id: profilUtilisateurConnecte.value?.num_exploitation?.toString() },
      };
    }

    nodes.value.push({
      key: '3',
      label: 'Alertes',
      data: { route: routeTEMP },
      icon: 'notification_important',
      type: 'Niveau1',
    });
  }

  if (droitParametrage)
    nodes.value.push({
      key: '4',
      label: 'Paramétrage',
      data: { route: { name: 'Parametrage' } },
      icon: 'settings',
      type: 'Niveau1',
    });

  if (isEleveur.value && nomExploitation.value === '') {
    let cheptelEleveur = all_exploitations.value.find(
      (e) => e.num_cheptel === profilUtilisateurConnecte.value?.num_exploitation
    );
    if (!cheptelEleveur) {
      await fetchAllExploitationsFromOrganisme(organismeConnecte.value?.code!, '', utilisateur.value.id!);
      cheptelEleveur = all_exploitations.value.find(
        (e) => e.num_cheptel === profilUtilisateurConnecte.value?.num_exploitation
      );
    }

    nomExploitation.value = cheptelEleveur?.nom ?? '';
  }

  if (themesDocuments.value.length) chargerSousMenuDocument();
  selectionnerActiveKey(nodes.value);
});
</script>
<style lang="scss" scoped>
@import '@/assets/style/scss/sidebarMenu.scss';
</style>
