import GedState from './stateInterface';

export default {
  getDonneesDocumentGed(state: GedState) {
    return state.donneesDocumentGed;
  },

  getListeGed(state: GedState) {
    return state.listeGed;
  },

  getListeDocumentGed(state: GedState) {
    return state.listeDocumentGed;
  },

  getListeTypeDocumentGed(state: GedState) {
    return state.listeTypeDocumentGed;
  },

  getExploitationSelectionne(state: GedState) {
    return state.exploitationSelectionne;
  },

  getDocumentSelectionne(state: GedState) {
    return state.documentSelectionne;
  },

  getThemesDocuments(state: GedState) {
    return state.themesDocuments;
  },

  getNavigationActiveGed(state: GedState) {
    return state.navigationActiveGed;
  },

  getExploitationsSelectionnes(state: GedState) {
    return state.exploitationsSelectionnees;
  },

  getNbResultat(state: GedState) {
    return state.nbResultat;
  },

  getRechercheEnCours(state: GedState) {
    return state.rechercheEnCours;
  },

  getLazyParamsDatatableGed(state: GedState) {
    return state.lazyParamsDatatableGed;
  },
};
