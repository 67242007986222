import type { Droit, Organisme, ProfilUtilisateur, Utilisateur } from '@/models';
import { computed, ref } from 'vue';
import { RouteLocationRaw, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import GedService from './GedService';

export default function UtilisateurService() {
  const store = useStore();
  const router = useRouter();
  const { clearExploitationSelectionne } = GedService();

  const utilisateur = computed((): Utilisateur => {
    return store.getters['UtilisateurModule/getUtilisateur'];
  });

  const organismeConnecte = computed((): Organisme => {
    return store.getters['UtilisateurModule/getOrganismeConnecte'];
  });

  const utilisateurs = computed((): Utilisateur[] => {
    return store.getters['UtilisateurModule/getAllUtilisateurs'];
  });

  const keycloak = computed((): any => {
    return store.getters['UtilisateurModule/getKeycloak'];
  });

  const profilUtilisateurs = computed((): ProfilUtilisateur[] => {
    return store.getters['UtilisateurModule/getProfilUtilisateurs'];
  });

  const droitsUtilisateur = computed((): Droit[] => {
    return store.getters['UtilisateurModule/getDroitUtilisateur'];
  });

  const filtreUtilisateur = computed((): any => {
    return store.getters['UtilisateurModule/getFiltreUtilisateur'];
  });

  const profilUtilisateurConnecte = computed((): ProfilUtilisateur | undefined => {
    if (organismeConnecte.value?.id)
      return utilisateur.value.profils_utilisateurs?.filter(
        (element) => element.ref_organisme!.id === organismeConnecte.value.id
      )[0];
    else return undefined;
  });

  const isEleveur = computed((): boolean => {
    if (
      profilUtilisateurConnecte.value &&
      profilUtilisateurConnecte.value.num_exploitation != undefined &&
      profilUtilisateurConnecte.value.num_exploitation != ''
    )
      return true;

    return false;
  });

  const routesActives = computed((): RouteLocationRaw[] => {
    return store.getters['UtilisateurModule/getRoutesActives'];
  });

  async function fetchUtilisateur(username: string) {
    await store.dispatch('UtilisateurModule/fetchUtilisateur', { username });
  }

  async function fetchProfilsFromUtilisateurs(code_organisme: string | undefined) {
    await store.dispatch('UtilisateurModule/fetchProfilsFromUtilisateurs', { code_organisme });
  }

  async function storeOrganismeConnecte(organismeSelectionne: Organisme | undefined) {
    await store.dispatch('UtilisateurModule/storeOrganismeConnecte', { organismeSelectionne });
  }

  async function ajouterUtilisateur(utilisateur: Utilisateur) {
    await store.dispatch('UtilisateurModule/ajouterUtilisateur', { utilisateur });
  }

  async function modifierUtilisateur(utilisateurEnvoye: Utilisateur, profil: ProfilUtilisateur | undefined) {
    await store.dispatch('UtilisateurModule/modifierUtilisateur', { utilisateurEnvoye, profil });

    if (utilisateurEnvoye.profils_utilisateurs!.length > 1) {
      fetchProfilsFromUtilisateurs(organismeConnecte.value?.code !== 'NATI' ? organismeConnecte.value.code : undefined);
    }
  }

  async function modifierMotDePasse(login: String, mdp: String) {
    await store.dispatch('UtilisateurModule/modifierMotDePasse', { login, mdp });
  }

  async function supprimerUtilisateurProfil(id: number) {
    await store.dispatch('UtilisateurModule/supprimerUtilisateurProfil', { id });
  }

  async function fetchAllUtilisateur(code_organisme: string) {
    await store.dispatch('UtilisateurModule/fetchAllUtilisateur', { code_organisme });
  }

  async function storeFiltreUtilisateur(filtre_utilisateur: {}) {
    await store.dispatch('UtilisateurModule/storeFiltreUtilisateur', { filtre_utilisateur });
  }

  function storeRoutesActives(routes: RouteLocationRaw[]) {
    store.commit('UtilisateurModule/setRoutesActives', routes);
  }

  async function changerOrganisme() {
    await storeOrganismeConnecte(ref<Organisme>().value);
    await storePathNameOrigine('/');
    clearExploitationSelectionne();
    router.push({ name: 'SelectionEcel' });
  }

  async function redirectionAccueilUtilisateur() {
    // Permet de rediriger l'utilisateur sur l'URL spécifique à laquelle il a essayé d'accéder (dans le cas où il clique sur un lien pointant une page précise par exemple)
    if (pathNameOrigine.value && pathNameOrigine.value !== '/') {
      const path = router.resolve(pathNameOrigine.value);
      if (path.name) {
        router.replace(pathNameOrigine.value);
        storePathNameOrigine('');
        return;
      }
    }
    if (isEleveur.value && utilisateur.value.consultationDocument) router.replace({ name: 'Ged' });
    else if (!profilUtilisateurConnecte.value?.administrateur && utilisateur.value.consultationAlerte)
      router.replace({ name: 'Alertes' });
    else router.replace({ name: 'Accueil' });
    storePathNameOrigine('');
  }

  const pathNameOrigine = computed((): string => {
    return store.getters['UtilisateurModule/getPathNameOrigine'];
  });
  async function storePathNameOrigine(path_name_origine: string) {
    store.dispatch('UtilisateurModule/storePathNameOrigine', { path_name_origine });
  }

  return {
    storeOrganismeConnecte,
    ajouterUtilisateur,
    droitsUtilisateur,
    organismeConnecte,
    fetchProfilsFromUtilisateurs,
    fetchAllUtilisateur,
    fetchUtilisateur,
    filtreUtilisateur,
    keycloak,
    modifierMotDePasse,
    modifierUtilisateur,
    profilUtilisateurs,
    supprimerUtilisateurProfil,
    utilisateur,
    utilisateurs,
    storeFiltreUtilisateur,
    isEleveur,
    profilUtilisateurConnecte,
    storeRoutesActives,
    routesActives,
    changerOrganisme,
    redirectionAccueilUtilisateur,
    pathNameOrigine,
    storePathNameOrigine,
  };
}
