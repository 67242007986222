import { KeycloakOnLoad } from 'keycloak-js';
import Config from '../config.json';

export const keycloakInitOptions = {
  url: Config.VUE_APP_KEYCLOAK_HOST_URL,
  realm: Config.VUE_APP_KEYCLOAK_REALM,
  clientId: Config.VUE_APP_KEYCLOAK_CLIENT_ID,
  onLoad: 'login-required' as KeycloakOnLoad,
};

export const apiConfig = {
  headers: { Authorization: `Bearer ${localStorage.getItem('vue-token')}` },
};
