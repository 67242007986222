import mutations from './mutations';
import actions from './actions';
import getters from './getters';
import ExploitationState from './stateInterface';
import { Cheptel, TypeExploitation } from '@/models';

export default {
  namespaced: true,
  state(): ExploitationState {
    return {
      exploitations: [] as Cheptel[],
      exploitationsSansHorsSiecl: [] as Cheptel[],
      type_exploitations: [] as TypeExploitation[],
    };
  },
  mutations,
  actions,
  getters,
};
