import BttcApi from '@/api/BttcAPI';
import { Bttc } from '@/models';
import fileDownload from 'js-file-download';
import { ActionContext } from 'vuex';
import BttcState from './stateInterface';

export default {
  async ajouterBttc(context: ActionContext<BttcState, any>, payload: { bttc: Bttc }) {
    const response = await BttcApi.ajouterBttc(payload.bttc);
    if (response.data.succeeded != true) {
      throw new Error(response.data.message);
    }
  },

  async cloturer(context: ActionContext<BttcState, any>, payload: { id: number }) {
    const response = await BttcApi.cloturer(payload.id);
    if (response.data.succeeded) {
      context.commit('setBttc', response.data.body);
    } else {
      throw new Error(response.data.message);
    }
  },

  async csv(context: ActionContext<BttcState, any>, payload: { id: number; nom: string }) {
    const response = await BttcApi.csv(payload.id);

    if (response.status === 200) {
      fileDownload(response.data, payload.nom + '.csv');
    } else {
      throw new Error(response.data.message);
    }
  },

  async fetchBttc(context: ActionContext<BttcState, any>, payload: { id: number }) {
    const response = await BttcApi.fetchBttc(payload.id);
    if (response.data.succeeded) {
      context.commit('setBttc', response.data.body);
    } else {
      throw new Error(response.data.message);
    }
  },

  async fetchAllBttc(context: ActionContext<BttcState, any>, payload: { code_organisme: string }) {
    const response = await BttcApi.fetchAllBttc(payload.code_organisme);
    if (response.data.succeeded) {
      context.commit('setAllBttc', response.data.body);
    } else {
      throw new Error(response.data.message);
    }
  },

  async fetchFamilleTypologie(context: ActionContext<BttcState, any>) {
    const response = await BttcApi.fetchFamilleTypologie();
    if (response.data.succeeded) {
      context.commit('setAllFamilleTypologie', response.data.body);
    } else {
      throw new Error(response.data.message);
    }
  },

  async fetchOrganismesAllBttc(context: ActionContext<BttcState, any>) {
    const response = await BttcApi.fetchOrganismesAllBttc();
    if (response.data.succeeded) {
      context.commit('setOrgansimesAllBttc', response.data.body);
    } else {
      throw new Error(response.data.message);
    }
  },

  async integrer(
    context: ActionContext<BttcState, any>,
    payload: { id: number; code_organisme: string; liste_resultat: number[] }
  ) {
    const response = await BttcApi.integrer(payload.id, payload.code_organisme, payload.liste_resultat);
    if (response.data.succeeded) {
      context.commit('setIntegration', payload.liste_resultat);
    } else {
      throw new Error(response.data.message);
    }
  },

  async pdf(context: ActionContext<BttcState, any>, payload: { id: number; nom: string; resultat_id: number }) {
    const response = await BttcApi.pdf(payload.id, payload.resultat_id);
    if (response.status === 200) {
      fileDownload(response.request.response, payload.nom + '.pdf');
    } else {
      throw new Error(response.data.message);
    }
  },

  async supprimerPdf(
    context: ActionContext<BttcState, any>,
    payload: { organisme_utilisateur: string; id: number; resultat_id: number }
  ) {
    const response = await BttcApi.supprimerPDF(payload.organisme_utilisateur, payload.id, payload.resultat_id);
    if (response.data.succeeded) {
      context.commit('setSupprimer', payload.resultat_id);
    } else {
      throw new Error(response.data.message);
    }
  },

  async valider(context: ActionContext<BttcState, any>, payload: { valider: boolean; id: string }) {
    const response = await BttcApi.valider(payload.valider, payload.id);
    if (response.data.succeeded != true) {
      throw new Error(response.data.message);
    }
  },
};
