import mutations from './mutations';
import actions from './actions';
import getters from './getters';
import PaysState from './stateInterface';
import { Pays } from '@/models';

export default {
  namespaced: true,
  state(): PaysState {
    return {
      pays: [] as Pays[],
    };
  },
  mutations,
  actions,
  getters,
};
