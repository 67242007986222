import AlerteState from './stateInterface';

export default {
  getAlertes(state: AlerteState) {
    return state.alertes;
  },
  getAlerte(state: AlerteState) {
    return state.alerte;
  },
  getAlertesTDB(state: AlerteState) {
    return state.alertesTDB;
  },
  getCheptelsAlertes(state: AlerteState) {
    return state.cheptelsAlertes;
  },
  getLazyParamsDatatableAlertes(state: AlerteState) {
    return state.lazyParamsDatatableAlertes;
  },
};
