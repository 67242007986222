import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Locale_FR from './constants/Locale_FR';
import ConfirmationService from 'primevue/confirmationservice';
import DialogService from 'primevue/dialogservice';
import ToastService from 'primevue/toastservice';
import Keycloak from 'keycloak-js';
import { keycloakInitOptions } from '@/constants/KeycloakOptions';

import 'primevue/resources/themes/nova/theme.css';
import '@/assets/style/theme/override-theme.scss';
import 'primeflex/primeflex.css';
import 'primevue/resources/primevue.css';
import 'primeicons/primeicons.css';

import PrimeVue from 'primevue/config';
import Sidebar from 'primevue/sidebar';
import PanelMenu from 'primevue/panelmenu';
import Breadcrumb from 'primevue/breadcrumb';
import Dropdown from 'primevue/dropdown';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import Card from 'primevue/card';
import Menubar from 'primevue/menubar';
import Listbox from 'primevue/listbox';
import Checkbox from 'primevue/checkbox';
import ConfirmDialog from 'primevue/confirmdialog';
import Dialog from 'primevue/dialog';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';
import TriStateCheckbox from 'primevue/tristatecheckbox';
import Tooltip from 'primevue/tooltip';
import MultiSelect from 'primevue/multiselect';
import Password from 'primevue/password';
import Textarea from 'primevue/textarea';
import RadioButton from 'primevue/radiobutton';
import Calendar from 'primevue/calendar';
import InputMask from 'primevue/inputmask';
import ProgressSpinner from 'primevue/progressspinner';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import OverlayPanel from 'primevue/overlaypanel';
import Toast from 'primevue/toast';
import Tree from 'primevue/tree';
import Menu from 'primevue/menu';
import Row from 'primevue/row';
import Avatar from 'primevue/avatar';
import ProgressBar from 'primevue/progressbar';
import InputSwitch from 'primevue/inputswitch';

import '@/assets/style/scss/setup.scss';
import '@/assets/style/scss/breadcrumb.scss';
import '@/assets/style/scss/menu.scss';
import '@/assets/style/scss/global.scss';
import '@/assets/style/scss/primevue-components-style.scss';
import '@/assets/style/scss/mediaqueries.scss';

import axios from 'axios';
import snakecaseKeys from 'snakecase-keys';
import camelcaseKeys from 'camelcase-keys';

import FilAriane from '@/components/UI/FilAriane.vue';
import ErrorDialog from '@/components/Dialog/ErrorDialog.vue';
import { ApiUrlUtilisateur } from './constants/ApiUrls';
import FileUpload from 'primevue/fileupload';

const keycloak = new Keycloak(keycloakInitOptions);

axios.defaults.transformResponse = [
  (data: any, headers: any) => {
    if (
      headers['content-type'] &&
      (headers['content-type'].includes('application/pdf') || headers['content-type'].includes('text/csv'))
    ) {
      return data;
    } else if (
      data &&
      headers['content-type'].includes('application/json') &&
      headers['content-disposition'] == undefined
    ) {
      return snakecaseKeys(JSON.parse(data), { deep: true });
    }
  },
];

axios.defaults.transformRequest = [
  (data: any) => {
    if (data) {
      return JSON.stringify(camelcaseKeys(data, { deep: true }));
    }
  },
];

keycloak
  .init({ onLoad: keycloakInitOptions.onLoad, pkceMethod: 'S256' })
  .then((auth) => {
    if (!auth) {
      window.location.reload();
    } else {
      keycloak.loadUserProfile().then(() => {
        store.dispatch('UtilisateurModule/storeToken', { token: keycloak.token });
        store.dispatch('UtilisateurModule/storeUsername', { username: keycloak.profile?.username });

        axios
          .get(`${ApiUrlUtilisateur}/idkeycloak`, {
            params: { idKeycloak: keycloak.profile?.username },
            headers: { Authorization: 'Bearer ' + keycloak.token },
          })
          .then((res: any) => {
            store.dispatch('UtilisateurModule/storeUtilisateurConnecte', { utilisateur: res.data.body });
            store.dispatch('UtilisateurModule/storeKeycloak', { keycloak: keycloak });
            const app = createApp(App);

            app.component('TabPanel', TabPanel);
            app.component('TabView', TabView);
            app.component('Sidebar', Sidebar);
            app.component('PanelMenu', PanelMenu);
            app.component('Breadcrumb', Breadcrumb);
            app.component('Dropdown', Dropdown);
            // eslint-disable-next-line
            app.component('Button', Button);
            app.component('InputText', InputText);
            app.component('InputNumber', InputNumber);
            app.component('Card', Card);
            app.component('Menubar', Menubar);
            app.component('Listbox', Listbox);
            app.component('Checkbox', Checkbox);
            app.component('ConfirmDialog', ConfirmDialog);
            // eslint-disable-next-line
            app.component('Dialog', Dialog);
            app.component('AccordionTab', AccordionTab);
            app.component('DataTable', DataTable);
            app.component('ColumnGroup', ColumnGroup);
            app.component('Column', Column);
            app.component('Accordion', Accordion);
            app.component('TriStateCheckbox', TriStateCheckbox);
            app.component('MultiSelect', MultiSelect);
            app.component('Password', Password);
            // eslint-disable-next-line
            app.component('Textarea', Textarea);
            app.component('Calendar', Calendar);
            app.component('RadioButton', RadioButton);
            app.component('FileUpload', FileUpload);
            app.component('InputMask', InputMask);
            app.component('ProgressSpinner', ProgressSpinner);
            app.component('OverlayPanel', OverlayPanel);
            app.component('Toast', Toast);
            app.component('Tree', Tree);
            // eslint-disable-next-line
            app.component('Menu', Menu);
            app.component('Row', Row);
            app.component('Avatar', Avatar);
            app.component('ProgressBar', ProgressBar);
            app.component('InputSwitch ', InputSwitch);

            app.component('FilAriane', FilAriane);
            app.component('ErrorDialog', ErrorDialog);

            app.directive('Tooltip', Tooltip);

            app.use(store);
            app.use(router);
            app.use(PrimeVue, { locale: Locale_FR });
            app.use(ConfirmationService);
            app.use(DialogService);
            app.use(ToastService);

            app.mount('#app');
          });
      });
    }
    setInterval(() => {
      keycloak
        .updateToken(70)
        .then((refreshed) => {
          if (refreshed) {
            store.dispatch('UtilisateurModule/storeToken', { token: keycloak.token });
          }
        })
        .catch(() => {
          keycloak.logout();
        });
    }, 60000);
  })
  .catch(() => {
    alert("Echec de l'authentification");
  });
