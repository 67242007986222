export default function DateUtils() {
  // Pour les objets et les tableaux d'objets
  function parseStringDatesToJavascriptDates(array: Array<any>, propertiesToConvert: Array<string>) {
    for (const object of array) {
      if (object !== undefined && object !== null) {
        for (const [key, value] of Object.entries(object)) {
          if (!value) continue;
          if (propertiesToConvert.includes(key) && typeof value === 'string') object[key] = new Date(value);
          else if (propertiesToConvert.includes(key) && typeof value === 'object') {
            if (Object.prototype.toString.call(value) === '[object Array]') {
              parseStringDatesToJavascriptDates(value as [], propertiesToConvert);
            } else parseStringDatesToJavascriptDates([value], propertiesToConvert);
          }
        }
      }
    }
  }

  return {
    parseStringDatesToJavascriptDates,
  };
}
