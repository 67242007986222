import type { Organisme, ProfilUtilisateur, Utilisateur } from '@/models';
import { ref } from 'vue';
import { RouteLocationRaw } from 'vue-router';
import UtilisateurState from './stateInterface';

export default {
  setUtilisateur(state: UtilisateurState, payload: Utilisateur) {
    state.utilisateur = payload;

    if (payload.profils_utilisateurs?.length === 1 || state.organismeConnecte?.id) {
      initialiserDroitsUtilisateur(state);
      if (payload.profils_utilisateurs?.length === 1)
        state.organismeConnecte = payload.profils_utilisateurs[0].ref_organisme!;

      const profilUtilisateur = payload.profils_utilisateurs?.find(
        (p) => p.ref_organisme_id === state.organismeConnecte.id
      )!;
      localStorage.setItem(
        'logo' + profilUtilisateur.ref_organisme!.code,
        JSON.stringify(profilUtilisateur.ref_organisme_icone_base64)
      );

      profilUtilisateur.profil?.droits_profils.forEach((droit) => {
        storeDroitUtilisateur(state, droit.ref_droits?.code_menu);
      });

      payload.droits_utilisateurs!.forEach((droit) => {
        storeDroitUtilisateur(state, droit.ref_droits?.code_menu);
      });
    }
  },

  setUtilisateurs(state: UtilisateurState, payload: Utilisateur[]) {
    state.utilisateurs = [];
    payload.forEach((utilisateur) => {
      const util = ref<Utilisateur>({
        id: utilisateur.id,
        nom: utilisateur.nom,
        prenom: utilisateur.prenom,
        email: utilisateur.email,
        num_telephone: utilisateur.num_telephone,
        nom_complet: utilisateur.nom + ' ' + utilisateur.prenom,
      });
      state.utilisateurs.push(util.value);
    });
  },

  setToken(state: UtilisateurState, payload: string) {
    state.token = payload;
  },

  setKeycloak(state: UtilisateurState, payload: any) {
    state.keycloak = payload;
  },

  setUsername(state: UtilisateurState, payload: string) {
    state.utilisateur.id_keycloak = payload;
  },

  setFiltreUtilisateur(state: UtilisateurState, payload: {}) {
    state.contexte_utilisateur.filtre_utilisateur = payload;
  },

  setOrganismeConnecte(state: UtilisateurState, payload: Organisme) {
    if (payload != undefined) {
      const profilUtilisateur = state.utilisateur.profils_utilisateurs?.filter(
        (profil) => profil.ref_organisme_id == payload.id
      )[0];

      initialiserDroitsUtilisateur(state);

      localStorage.setItem('logo' + payload.code, JSON.stringify(profilUtilisateur!.ref_organisme_icone_base64));

      profilUtilisateur!.profil?.droits_profils.forEach((droit) => {
        storeDroitUtilisateur(state, droit.ref_droits?.code_menu);
      });

      state.utilisateur.droits_utilisateurs!.forEach((droit) => {
        if (droit.ref_organisme_id! == payload.id) {
          storeDroitUtilisateur(state, droit.ref_droits?.code_menu);
        }
      });
    }

    state.organismeConnecte = payload;
  },

  setUtilisateurProfils(state: UtilisateurState, payload: ProfilUtilisateur[]) {
    payload.forEach((profil) => {
      profil.utilisateur!.num_telephone =
        profil.utilisateur!.num_telephone.substring(0, 2) +
        ' ' +
        profil.utilisateur!.num_telephone.substring(2, 4) +
        ' ' +
        profil.utilisateur!.num_telephone.substring(4, 6) +
        ' ' +
        profil.utilisateur!.num_telephone.substring(6, 8) +
        ' ' +
        profil.utilisateur!.num_telephone.substring(8, 10);
    });
    state.profilUtilisateurs = payload;
  },

  ajouterUtilisateurProfils(state: UtilisateurState, payload: Utilisateur) {
    if (payload.email != undefined) {
      const profils_utilisateur = ref<ProfilUtilisateur[]>([]);
      profils_utilisateur.value = payload.profils_utilisateurs!;

      profils_utilisateur.value.forEach((profil) => {
        if (state.organismeConnecte?.code == 'NATI' || profil.ref_organisme?.code == state.organismeConnecte!.code) {
          profil.utilisateur = {
            id: payload.id,
            id_keycloak: payload.id_keycloak,
            nom: payload.nom,
            prenom: payload.prenom,
            email: payload.email,
            num_telephone:
              payload.num_telephone.substring(0, 2) +
              ' ' +
              payload.num_telephone.substring(2, 4) +
              ' ' +
              payload.num_telephone.substring(4, 6) +
              ' ' +
              payload.num_telephone.substring(6, 8) +
              ' ' +
              payload.num_telephone.substring(8, 10),
            droits_utilisateurs: payload.droits_utilisateurs,
          };
        }
      });

      state.profilUtilisateurs = state.profilUtilisateurs.concat(profils_utilisateur.value);
    }
  },

  modifierUtilisateur(
    state: UtilisateurState,
    payload: { profil: ProfilUtilisateur; utilisateur: Utilisateur; id: number }
  ) {
    if (payload.profil != undefined) {
      const index = state.profilUtilisateurs.findIndex((profil) => profil.id == payload.profil.id);
      payload.profil.id = payload.id;
      payload.profil.utilisateur!.num_telephone =
        payload.profil.utilisateur!.num_telephone.substring(0, 2) +
        ' ' +
        payload.profil.utilisateur!.num_telephone.substring(2, 4) +
        ' ' +
        payload.profil.utilisateur!.num_telephone.substring(4, 6) +
        ' ' +
        payload.profil.utilisateur!.num_telephone.substring(6, 8) +
        ' ' +
        payload.profil.utilisateur!.num_telephone.substring(8, 10);
      state.profilUtilisateurs[index] = payload.profil;

      if (payload.utilisateur.id == state.utilisateur.id) {
        state.utilisateur.prenom = payload.utilisateur!.prenom;
        state.utilisateur.nom = payload.utilisateur!.nom;
        state.utilisateur.email = payload.utilisateur!.email;
        state.utilisateur.num_telephone =
          payload.utilisateur!.num_telephone.substring(0, 2) +
          ' ' +
          payload.utilisateur!.num_telephone.substring(2, 4) +
          ' ' +
          payload.utilisateur!.num_telephone.substring(4, 6) +
          ' ' +
          payload.utilisateur!.num_telephone.substring(6, 8) +
          ' ' +
          payload.utilisateur!.num_telephone.substring(8, 10);

        state.utilisateur.profils_utilisateurs!.find(
          (element) => element.ref_organisme!.id == state.organismeConnecte?.id
        )!.secteur = payload.profil.secteur;

        initialiserDroitsUtilisateur(state);

        payload.profil.profil!.droits_profils.forEach((droit) => {
          storeDroitUtilisateur(state, droit.ref_droits?.code_menu);
        });
      }
    } else {
      if (payload.utilisateur.id == state.utilisateur.id) {
        state.utilisateur.prenom = payload.utilisateur!.prenom;
        state.utilisateur.nom = payload.utilisateur!.nom;
        state.utilisateur.email = payload.utilisateur!.email;
        state.utilisateur.num_telephone =
          payload.utilisateur!.num_telephone.substring(0, 2) +
          ' ' +
          payload.utilisateur!.num_telephone.substring(2, 4) +
          ' ' +
          payload.utilisateur!.num_telephone.substring(4, 6) +
          ' ' +
          payload.utilisateur!.num_telephone.substring(6, 8) +
          ' ' +
          payload.utilisateur!.num_telephone.substring(8, 10);
      }
    }
  },

  supprimerUtilisateurProfil(state: UtilisateurState, payload: number) {
    state.profilUtilisateurs = state.profilUtilisateurs.filter((profil) => profil.id != payload);
  },

  setRoutesActives(state: UtilisateurState, payload: RouteLocationRaw[]) {
    state.routesActives = payload;
  },
  setPathNameOrigine(state: UtilisateurState, payload: string) {
    state.pathNameOrigine = payload;
  },
};

function storeDroitUtilisateur(state: UtilisateurState, code_menu?: string) {
  switch (code_menu) {
    case 'gesUt':
      state.utilisateur.gestionUtilisateur = true;
      break;
    case 'conUt':
      state.utilisateur.consultationUtilisateur = true;
      break;
    case 'gesEl':
      state.utilisateur.gestionAffectation = true;
      break;
    case 'conEl':
      state.utilisateur.consultationAffectation = true;
      break;
    case 'gesPr':
      state.utilisateur.gestionProfil = true;
      break;
    case 'conPr':
      state.utilisateur.consultationProfil = true;
      break;
    case 'calBT':
      state.utilisateur.calculBttc = true;
      break;
    case 'conBT':
      state.utilisateur.consultationBttc = true;
      break;
    case 'gesBT':
      state.utilisateur.gestionBttc = true;
      break;
    case 'conDo':
      state.utilisateur.consultationDocument = true;
      break;
    case 'gesDo':
      state.utilisateur.gestionDocument = true;
      break;
    case 'conAl':
      state.utilisateur.consultationAlerte = true;
      break;
    case 'conSe':
      state.utilisateur.consultationSeuilAlerte = true;
      break;
    case 'gesSe':
      state.utilisateur.gestionSeuilAlerte = true;
      break;
  }
}

function initialiserDroitsUtilisateur(state: UtilisateurState) {
  state.utilisateur.gestionUtilisateur = false;
  state.utilisateur.consultationUtilisateur = false;
  state.utilisateur.gestionAffectation = false;
  state.utilisateur.consultationAffectation = false;
  state.utilisateur.gestionProfil = false;
  state.utilisateur.consultationProfil = false;
  state.utilisateur.calculBttc = false;
  state.utilisateur.consultationBttc = false;
  state.utilisateur.gestionBttc = false;
  state.utilisateur.consultationDocument = false;
  state.utilisateur.gestionDocument = false;
  state.utilisateur.consultationSeuilAlerte = false;
  state.utilisateur.gestionSeuilAlerte = false;
  state.utilisateur.consultationAlerte = false;
}
