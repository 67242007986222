import type { AdressePostale } from '@/models';
import { ref } from 'vue';
import AdressePostaleState from './stateInterface';

export default {
  setAdressesPostales(state: AdressePostaleState, payload: AdressePostale[]) {
    state.adresses_postales = [];
    payload.forEach((adressePostale) => {
      const adresse = ref<AdressePostale>(adressePostale);
      adresse.value.libelle_complet = adresse.value.code_postal + ' - ' + adresse.value.libelle;
      state.adresses_postales.push(adresse.value);
    });
  },
};
