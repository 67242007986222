import axios, { AxiosResponse } from 'axios';
import { ApiUrlAdressePostale } from '@/constants/ApiUrls';
import { ResponseModel } from '@/models';
import { apiConfig } from '@/config/UtilisateurConfig';

export default {
  async fetchAdressePostales(): Promise<AxiosResponse<ResponseModel>> {
    return axios.get(`${ApiUrlAdressePostale}`, apiConfig.value);
  },
};
