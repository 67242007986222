import OrganismeAPI from '@/api/OrganismeAPI';
import { ActionContext } from 'vuex';
import OrganismeState from './stateInterface';

export default {
  async fetchAllOrganismes(context: ActionContext<OrganismeState, any>) {
    const response = await OrganismeAPI.fetchAllOrganismes();

    if (response.data.succeeded) {
      context.commit('setOrganismes', response.data.body);
    } else {
      throw new Error(response.data.message);
    }
  },
};
