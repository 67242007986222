import mutations from './mutations';
import actions from './actions';
import getters from './getters';
import UtilisateurState from './stateInterface';
import { ContexteUtilisateur, Droit, Organisme, ProfilUtilisateur, Utilisateur } from '@/models';
import { RouteLocationRaw } from 'vue-router';
import { getSessionStorage } from '@/store';

export default {
  namespaced: true,
  state(): UtilisateurState {
    return {
      utilisateur: {} as Utilisateur,
      utilisateurs: [] as Utilisateur[],
      droits: [] as Droit[],
      profilUtilisateurs: [] as ProfilUtilisateur[],
      token: '' as string,
      keycloak: {} as any,
      contexte_utilisateur: {} as ContexteUtilisateur,
      routesActives: [] as RouteLocationRaw[],
      pathNameOrigine: '' as string,
      organismeConnecte:
        (getSessionStorage('UtilisateurModule', 'organismeConnecte') as Organisme) || ({} as Organisme),
    };
  },
  mutations,
  actions,
  getters,
};
