export default {
  startsWith: 'Commence par',
  contains: 'Contient',
  notContains: 'Ne contient pas',
  endsWith: 'Finit par',
  equals: 'Égal à',
  notEquals: 'Différent de',
  noFilter: 'Sans filtre',
  lt: 'Inférieur(e) à',
  lte: 'Inférieur(e) ou égal(e) à',
  gt: 'Supérieur(e) à',
  gte: 'Supérieur(e) ou égal(e) à',
  dateIs: 'Date is',
  dateIsNot: 'Date is not',
  dateBefore: 'Date is before',
  dateAfter: 'Date is after',
  clear: 'Effacer',
  apply: 'Appliquer',
  matchAll: 'Respecte toutes les règles',
  matchAny: 'Respecte au moins une règle',
  addRule: 'Ajouter une règle',
  removeRule: 'Effacer la règle',
  accept: 'Oui',
  reject: 'Non',
  choose: 'Choisir',
  upload: 'Upload',
  cancel: 'Annuler',
  dayNames: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
  dayNamesShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
  dayNamesMin: ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa'],
  monthNames: [
    'Janvier',
    'Février',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Août',
    'Septembre',
    'Octobre',
    'Novembre',
    'Décembre',
  ],
  monthNamesShort: ['Jan', 'Fev', 'Mar', 'Avr', 'Mai', 'Jui', 'Juil', 'Aoû', 'Sep', 'Oct', 'Nov', 'Déc'],
  chooseYear: `Choisir l'année`,
  chooseMonth: `Choisir le mois`,
  chooseDate: 'Choisir la date',
  prevDecade: 'Décennie précédente',
  nextDecade: 'Décennie suivante',
  prevYear: 'Année précédente',
  nextYear: 'Année suivante',
  prevMonth: 'Mois précédent',
  nextMonth: 'Mois suivant',
  prevHour: 'Heure précédente',
  nextHour: 'Heure suivante',
  prevMinute: 'Minute précédente',
  nextMinute: 'Minute suivante',
  prevSecond: 'Seconde précédente',
  nextSecond: 'Seconde suivante',
  am: 'am',
  pm: 'pm',
  today: "Aujourd'hui",
  weekHeader: 'Sem',
  firstDayOfWeek: 1,
  dateFormat: 'dd/mm/yy',
  weak: 'Weak',
  medium: 'Medium',
  strong: 'Strong',
  passwordPrompt: 'Enter a password',
  emptyFilterMessage: 'Aucun résultat trouvé',
  searchMessage: '{0} résultats sont disponibles',
  selectionMessage: '{0} items sélectionnés',
  emptySelectionMessage: 'Aucun item sélectionné',
  emptySearchMessage: 'Aucun résultat trouvé',
  emptyMessage: 'Aucune option disponible',
  aria: {
    trueLabel: 'Oui',
    falseLabel: 'Non',
    nullLabel: 'non sélectionné',
    star: '1 étoile',
    stars: '{star} étoiles',
    selectAll: 'Tout sélectionner',
    unselectAll: 'Tout désélectionner',
    close: 'Fermer',
    previous: 'Précédent',
    next: 'Suivant',
    navigation: 'Navigation',
    scrollTop: 'Haut de la page',
    moveTop: 'Déplacer le haut',
    moveUp: 'Déplacement vers le haut',
    moveDown: 'Déplacement vers le bas',
    moveBottom: 'Déplacer le bas',
    moveToTarget: 'Déplacer la cible',
    moveToSource: 'Déplacer la source',
    moveAllToTarget: 'Déplacer tout vers la cible',
    moveAllToSource: 'Déplacer tout vers la source',
    pageLabel: '{page}',
    firstPageLabel: 'Première Page',
    lastPageLabel: 'Dernière Page',
    nextPageLabel: 'Page suivante',
    prevPageLabel: 'Previous Page',
    rowsPerPageLabel: 'Ligne par page',
    previousPageLabel: 'Page précédente',
    jumpToPageDropdownLabel: 'Aller à la page liste déroulante',
    jumpToPageInputLabel: 'Aller à la page ',
    selectRow: 'Ligne sélectionné',
    unselectRow: 'Ligne non sélectionnée',
    expandRow: 'Ligne étendue',
    collapseRow: 'Row Collapsed',
    showFilterMenu: 'Afficher le menu des filtres',
    hideFilterMenu: 'Cacher le menu des filtres',
    filterOperator: 'Opérateur du filtre',
    filterConstraint: 'Contrainte du filtre',
    editRow: 'Editer la ligne',
    saveEdit: 'Enregistrer la modification',
    cancelEdit: 'Annuler la modification',
    listView: 'Vue liste',
    gridView: 'Vue grille',
    slide: 'Diapositive',
    slideNumber: '{slideNumber}',
    zoomImage: 'Zoom Image',
    zoomIn: 'Zoom avant',
    zoomOut: 'Zoom arrière',
    rotateRight: 'Rotation à droite',
    rotateLeft: 'Rotation à gauche',
  },
};
