import ExploitationAPI from '@/api/ExploitationAPI';
import { Cheptel, Utilisateur, UtilisateurExploitation } from '@/models';
import { ActionContext } from 'vuex';
import ExploitationState from './stateInterface';

export default {
  async fetchAllExploitations(context: ActionContext<ExploitationState, any>) {
    const response = await ExploitationAPI.fetchExploitations();
    if (response.data.succeeded) {
      context.commit('setExploitations', response.data.body);
    } else {
      throw new Error(response.data.message);
    }
  },

  async fetchAllExploitationsFromOrganisme(
    context: ActionContext<ExploitationState, any>,
    payload: { code_organisme: string; secteur: string; id_utilisateur: number }
  ) {
    const response = await ExploitationAPI.fetchAllExploitationsFromOrganisme(
      payload.code_organisme,
      payload.secteur,
      payload.id_utilisateur
    );
    if (response.data.succeeded) {
      context.commit('setExploitations', response.data.body);
    } else {
      context.commit('setExploitations', null);
    }
  },

  async fetchAllExploitationsFromOrganismeSansHorsSiecl(
    context: ActionContext<ExploitationState, any>,
    payload: { code_organisme: string; secteur: string; id_utilisateur: number }
  ) {
    const response = await ExploitationAPI.fetchAllExploitationsFromOrganismeSansHorsSiecl(
      payload.code_organisme,
      payload.secteur,
      payload.id_utilisateur
    );
    if (response.data.succeeded) {
      context.commit('setExploitations', response.data.body);
    } else {
      throw new Error(response.data.message);
    }
  },

  async modifierUtilisateursAffectes(
    context: ActionContext<ExploitationState, any>,
    payload: { utilisateursAffectes: UtilisateurExploitation[]; utilisateurs: Utilisateur[] }
  ) {
    const response = await ExploitationAPI.modifierUtilisateurAffectes(payload.utilisateursAffectes);
    if (response.data.succeeded) {
      if (payload.utilisateursAffectes[0] != undefined) {
        context.commit('modifierUtilisateurAffectes', {
          utilisateurs: payload.utilisateurs,
          id: payload.utilisateursAffectes[0].cheptel_id,
        });
      }
    } else {
      throw new Error(response.data.message);
    }
  },

  async modifierCommentaire(
    context: ActionContext<ExploitationState, any>,
    payload: { cheptelId: number; commentaire: string }
  ) {
    const response = await ExploitationAPI.modifierCommentaire(payload.cheptelId, payload.commentaire);
    if (response.data.succeeded) {
      context.commit('modifierCommentaire', {
        cheptelId: payload.cheptelId,
        commentaire: payload.commentaire,
      });
    } else {
      throw new Error(response.data.message);
    }
  },

  async ajouterExploitation(context: ActionContext<ExploitationState, any>, payload: { exploitation: Cheptel }) {
    const response = await ExploitationAPI.ajouterExploitation(payload.exploitation);
    if (response.data.succeeded) {
      context.commit('ajouterExploitation', response.data.body);
    } else {
      throw new Error(response.data.message);
    }
  },

  async modifierExploitation(context: ActionContext<ExploitationState, any>, payload: { exploitation: Cheptel }) {
    const response = await ExploitationAPI.modifierExploitation(payload.exploitation);
    if (response.data.succeeded) {
      context.commit('modifierExploitation', response.data.body);
    } else {
      throw new Error(response.data.message);
    }
  },

  async supprimerExploitationHorsSiecl(context: ActionContext<ExploitationState, any>, payload: { id: number }) {
    const response = await ExploitationAPI.supprimerExploitationHorsSiecl(payload.id!);
    if (response.data.succeeded) {
      context.commit('supprimerExploitationHorsSiecl', payload.id);
    } else {
      throw new Error(response.data.message);
    }
  },

  async supprimerUtilisateursAffectes(context: ActionContext<ExploitationState, any>, payload: { id: number }) {
    const response = await ExploitationAPI.supprimerUtilisateursAffectes(payload.id);
    if (!response.data.succeeded) {
      throw new Error(response.data.message);
    }
  },

  async fetchAllTypeExploitations(context: ActionContext<ExploitationState, any>) {
    const response = await ExploitationAPI.fetchTypeExploitations();
    if (response.data.succeeded) {
      context.commit('setTypeExploitations', response.data.body);
    } else {
      throw new Error(response.data.message);
    }
  },

  async fetchExploitationsDetenteur(
    context: ActionContext<ExploitationState, any>,
    payload: { num_detenteur: string }
  ) {
    const response = await ExploitationAPI.fetchExploitationsDetenteur(payload.num_detenteur);
    if (response.data.succeeded) {
      context.commit('setExploitations', response.data.body);
    } else {
      throw new Error(response.data.message);
    }
  },

  async fetchNumeroDetenteur(context: ActionContext<ExploitationState, any>, payload: { num_exploitation: string }) {
    const response = await ExploitationAPI.fetchNumeroDetenteur(payload.num_exploitation);
    if (response.data.succeeded) {
      return response.data.body;
    }
  },
};
