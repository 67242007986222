import type { Alerte, AlerteTabDeBord, Cheptel, EvenementLazyLoading } from '@/models';
import AlerteState from './stateInterface';
import DateUtils from '@/utils/DateUtils';

const { parseStringDatesToJavascriptDates } = DateUtils();
export default {
  setAlertes(state: AlerteState, payload: Alerte[]) {
    state.alertes = payload;
  },
  setAlerte(state: AlerteState, payload: Alerte) {
    state.alerte = payload;
  },
  setAlertesTDB(state: AlerteState, payload: AlerteTabDeBord[]) {
    state.alertesTDB = payload;
  },
  setCheptelsAlertes(state: AlerteState, payload: Cheptel[]) {
    parseStringDatesToJavascriptDates(payload, ['date_dernier_ctrl']);
    payload.forEach((a) => {
      //Calcul du nombre d'alerte critique
      const nb_alerte_critique = a.alertes_exploitation?.filter(
        (ae) => ae.couleur_alerte === '#E96355' || ae.couleur_alerte === '#F39B4C'
      ).length;
      a.nb_alerte_critique = nb_alerte_critique;
    });

    state.cheptelsAlertes = payload;
  },
  modifierAlerte(state: AlerteState, payload: Alerte) {
    const index = state.alertes.findIndex((alerte: Alerte) => alerte.id == payload.id);
    if (index >= 0) state.alertes[index] = payload;
  },
  setLazyParamsDatatableAlertes(state: AlerteState, payload: EvenementLazyLoading) {
    state.lazyParamsDatatableAlertes = payload;
  },
};
