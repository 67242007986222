import type { Bttc, Organisme } from '@/models';
import BttcState from './stateInterface';

export default {
  setAllBttc(state: BttcState, payload: Bttc[]) {
    state.listeBttc = payload;
  },

  setAllFamilleTypologie(state: BttcState, payload: Bttc[]) {
    state.listeFamilleTypologie = payload;
  },

  setOrgansimesAllBttc(state: BttcState, payload: Organisme[]) {
    state.listeOrganismeBttc = payload;
  },

  setIntegration(state: BttcState, payload: number[]) {
    state.bttc.resultat_btt_cs?.forEach((bttc) => {
      if (payload.includes(bttc.id)) {
        bttc.etat = 2;
      }
    });
  },

  setSupprimer(state: BttcState, payload: number) {
    state.bttc.resultat_btt_cs?.forEach((bttc) => {
      if (payload == bttc.id) {
        bttc.etat = 3;
      }
    });
  },

  setBttc(state: BttcState, payload: Bttc) {
    state.bttc = payload;

    state.bttc.resultat_btt_cs!.forEach((bttc) => {
      if (bttc.cheptel.cheptel_periode_conduite == null) {
        bttc.cheptel.cheptel_periode_conduite = {
          detenteur: {
            nom: '',
          },
        };
      }
    });
  },
};
