import Config from '../config.json';

export const ApiUrl = Config.CAPWEB_API_BASE_URL;
export const ApiUrlUtilisateur = `${ApiUrl}/Utilisateurs`;
export const ApiUrlProfilUtilisateur = `${ApiUrl}/ProfilsUtilisateur`;
export const ApiUrlDroit = `${ApiUrl}/Droits`;
export const ApiUrlOrganisme = `${ApiUrl}/Organismes`;
export const ApiUrlProfil = `${ApiUrl}/Profils`;
export const ApiUrlExploitation = `${ApiUrl}/Exploitations`;
export const ApiUrlAdressePostale = `${ApiUrl}/AdressesPostales`;
export const ApiUrlRace = `${ApiUrl}/Races`;
export const ApiUrlPays = `${ApiUrl}/Pays`;
export const ApiUrlBttc = `${ApiUrl}/Bttc`;
export const ApiUrlGed = `${ApiUrl}/Ged`;
export const ApiUrlAlerte = `${ApiUrl}/Alertes`;
