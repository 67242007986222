import ProfilState from './stateInterface';
export default {
  getProfils(state: ProfilState) {
    return state.profils;
  },

  getAllOrganismeProfils(state: ProfilState) {
    return state.organismes;
  },
};
