import BttcState from './stateInterface';

export default {
  getBttc(state: BttcState) {
    return state.bttc;
  },

  getListeBttc(state: BttcState) {
    return state.listeBttc;
  },

  getListeFamille(state: BttcState) {
    return state.listeFamilleTypologie;
  },

  getListeOrganismeBttc(state: BttcState) {
    return state.listeOrganismeBttc;
  },
};
