import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import DroitModule from '@/store/droit/index';
import ExploitationModule from '@/store/exploitation/index';
import OrganismeModule from '@/store/organisme/index';
import PaysModule from '@/store/pays/index';
import ProfilModule from '@/store/profil/index';
import RaceModule from '@/store/race/index';
import UtilisateurModule from '@/store/utilisateur/index';
import AdressePostaleModule from '@/store/adressePostale/index';
import BttcModule from '@/store/bttc/index';
import GedModule from '@/store/ged/index';
import AlerteModule from '@/store/alerte/index';

const keySessionStorage = 'capwebstore';

export function getSessionStorage(module: string, property: string) {
  const storage = sessionStorage.getItem(keySessionStorage);
  if (storage === null) return null;
  const sto = JSON.parse(storage);
  let mod = sto[module];
  if (mod === undefined) mod = module;
  return mod[property];
}

export default createStore({
  modules: {
    AdressePostaleModule,
    DroitModule,
    ExploitationModule,
    OrganismeModule,
    PaysModule,
    ProfilModule,
    RaceModule,
    UtilisateurModule,
    BttcModule,
    GedModule,
    AlerteModule,
  },
  plugins: [
    createPersistedState({
      paths: ['UtilisateurModule.organismeConnecte', 'GedModule.exploitationSelectionne'],
      storage: window.sessionStorage,
      key: keySessionStorage,
    }),
  ],
});
