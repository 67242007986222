import mutations from './mutations';
import actions from './actions';
import getters from './getters';
import RaceState from './stateInterface';
import { Race } from '@/models';

export default {
  namespaced: true,
  state(): RaceState {
    return {
      races: [] as Race[],
    };
  },
  mutations,
  actions,
  getters,
};
