import axios, { AxiosResponse } from 'axios';
import { ApiUrlUtilisateur, ApiUrlProfilUtilisateur } from '@/constants/ApiUrls';
import { ResponseModel, Utilisateur } from '@/models';
import { apiConfig, apiConfigSansHeader } from '@/config/UtilisateurConfig';

export default {
  async fetchUtilisateur(login: string): Promise<AxiosResponse<ResponseModel>> {
    return axios.get(`${ApiUrlUtilisateur}/keycloak`, {
      params: { idKeycloak: login },
      headers: apiConfigSansHeader.value,
    });
  },

  async fetchAllUtilisateur(code_organisme: string): Promise<AxiosResponse<ResponseModel>> {
    return axios.get(`${ApiUrlUtilisateur}`, {
      params: { codeOrg: code_organisme },
      headers: apiConfigSansHeader.value,
    });
  },

  async fetchProfilsFromUtilisateurs(code_organisme: string | undefined): Promise<AxiosResponse<ResponseModel>> {
    if (code_organisme != undefined) {
      return axios.get(`${ApiUrlProfilUtilisateur}/organisme`, {
        params: { codeOrg: code_organisme },
        headers: apiConfigSansHeader.value,
      });
    } else {
      return axios.get(`${ApiUrlProfilUtilisateur}`, apiConfig.value);
    }
  },

  async ajouterUtilisateur(utilisateur: Utilisateur): Promise<AxiosResponse<ResponseModel>> {
    return await axios.post(`${ApiUrlUtilisateur}`, utilisateur, apiConfig.value);
  },

  async modifierMotDePasse(login: String, mdp: String): Promise<AxiosResponse<ResponseModel>> {
    return axios.put(`${ApiUrlUtilisateur}/keycloak/motdepasse`, null, {
      params: { username: login, motDePasse: mdp },
      headers: apiConfigSansHeader.value,
    });
  },

  async modifierUtilisateur(utilisateur: Utilisateur): Promise<AxiosResponse<ResponseModel>> {
    return await axios.put(`${ApiUrlUtilisateur}`, utilisateur, apiConfig.value);
  },

  async supprimerUtilisateur(id: number): Promise<AxiosResponse<ResponseModel>> {
    return axios.delete(`${ApiUrlProfilUtilisateur}`, {
      params: { id: id },
      headers: apiConfigSansHeader.value,
    });
  },
};
