<template>
  <Breadcrumb :home="props.home" :model="props.itemList">
    <template #item="{ item }">
      <router-link :to="item.to" v-if="item.to != undefined">
        <div class="flex align-items-center">
          <span class="material-icons" style="padding-right: 8px; font-size: 32px" :style="getItemStyle(item)">
            {{ item.icon }}
          </span>
          <a style="font-size: 24px" :style="getItemStyle(item)">{{ item.label }}</a>
        </div>
      </router-link>
    </template>
  </Breadcrumb>
</template>

<script lang="ts" setup>
import { watch, onMounted } from 'vue';
import { isEmpty } from 'lodash';
import { MenuItem } from 'primevue/menuitem';
import { RouteLocationRaw } from 'vue-router';
import UtilisateurService from '@/services/UtilisateurService';

const { storeRoutesActives } = UtilisateurService();

const props = defineProps({
  itemList: {
    type: Object as () => MenuItem[],
    default: [] as MenuItem[],
  },
  home: {
    type: Object as () => MenuItem,
    required: true,
  },
});

watch(
  () => [props.home, props.itemList],
  () => {
    selectedActiveItemsSidebar();
  }
);

function getItemStyle(item: MenuItem) {
  if (isEmpty(props.itemList)) {
    //Style Home
    return { ...item.style, color: 'var(--secondary-color)' };
  } else {
    //Style items
    const index = props.itemList.findIndex((i) => i.label === item.label);

    if (index !== -1 && isLastItem(index)) return { ...item.style, color: 'var(--secondary-color)' };

    return { ...item.style, color: 'var(--text-color)' };
  }
}

function isLastItem(index: number) {
  return props.itemList[props.itemList.length - 1].label === props.itemList[index].label;
}

function selectedActiveItemsSidebar() {
  const lstRouteActive: RouteLocationRaw[] = [];
  const activeNiveau1: any = props.home.to;
  if (activeNiveau1 && activeNiveau1.name) {
    lstRouteActive.push(activeNiveau1);
  }

  if (props.itemList.length) {
    if (activeNiveau1.name === 'Ged') {
      props.itemList.forEach((i) => {
        if (i.to) lstRouteActive.push(i.to);
      });
    }
  }

  storeRoutesActives(lstRouteActive);
}

onMounted(() => {
  selectedActiveItemsSidebar();
});
</script>
