import GedAPI from '@/api/GedAPI';
import fileDownload from 'js-file-download';
import { ActionContext } from 'vuex';
import GedState from './stateInterface';
import FunctionUtils from '@/utils/FunctionUtils';
import { Cheptel, DocumentGed, EvenementLazyLoading, RechercheGed } from '@/models';

const { throwError } = FunctionUtils();

export default {
  async fetchAllGed(context: ActionContext<GedState, any>, payload: { id_cheptel: number }) {
    const response = await GedAPI.fetchAllGed(payload.id_cheptel);
    if (response.data.succeeded) {
      context.commit('setAllGed', response.data.body);
    } else {
      throw new Error(response.data.message);
    }
  },

  async searchFichier(
    context: ActionContext<GedState, any>,
    payload: {
      recherche: RechercheGed;
    }
  ) {
    const response = await GedAPI.fetchAllDocByFiltre(payload.recherche);
    if (response.data.succeeded) {
      context.commit('setAllDocByType', response.data.body);
    } else {
      throw new Error(response.data.message);
    }
  },

  async ajouterDocumentGed(
    _: ActionContext<GedState, any>,
    payload: {
      type_document: string;
      nom: string;
      donnees: string;
      nom_detenteur: string;
      adresse_detenteur: string;
      detenteur_code_postal: string;
      code_client: string;
      code_orga: string;
    }
  ) {
    const response = await GedAPI.ajouterDocumentGed(
      payload.type_document,
      payload.nom,
      payload.donnees,
      payload.nom_detenteur,
      payload.adresse_detenteur,
      payload.detenteur_code_postal,
      payload.code_client,
      payload.code_orga
    );
    if (response.data.succeeded != true) {
      throw new Error(response.data.message);
    }
  },

  async telechargerDocumentGed(context: ActionContext<GedState, any>, payload: { nuged: string; nom: string }) {
    const response = await GedAPI.fetchDocumentGed(payload.nuged);
    if (response.status == 200) {
      fileDownload(response.data, payload.nom);
    } else {
      throw new Error(response.data.message);
    }
  },

  async supprimerDocument(context: ActionContext<GedState, any>, payload: { id: string }) {
    const response = await GedAPI.supprimerDocument(payload.id);
    if (response.data.succeeded) {
      context.commit('supprimerDocument', payload.id);
    } else {
      throw new Error(response.data.message);
    }
  },

  async fetchDocumentGed(context: ActionContext<GedState, any>, payload: { nuged: string }) {
    const response = await GedAPI.fetchDocumentGed(payload.nuged);
    if (response.status == 200) {
      context.commit('setDocumentGed', response.data);
    } else {
      throw new Error(response.data.message);
    }
  },

  async fetchTypeDeDocumentGed(context: ActionContext<GedState, any>) {
    const response = await GedAPI.fetchTypeDeDocumentGed();
    if (response.data.succeeded) {
      context.commit('setTypeDocumentGed', response.data.body);
    } else {
      throw new Error(response.data.message);
    }
  },

  async mettreLuDocument(context: ActionContext<GedState, any>, payload: { nuged: string }) {
    const response = await GedAPI.mettreLuDocument(payload.nuged);
    if (response.data.succeeded) {
      context.commit('mettreDocumentLu', payload.nuged);
    } else {
      throw new Error(response.data.message);
    }
  },

  async fetchThemesDocumentsByCheptel(context: ActionContext<GedState, any>, payload: { num_cheptel: string }) {
    try {
      const response = await GedAPI.fetchThemesDocumentsByCheptel(payload.num_cheptel);
      if (response.data.succeeded && response.data.body) context.commit('setThemesDocuments', response.data.body);
      else throw new Error(response.data.message);
    } catch (error: any) {
      throwError(error);
    }
  },
  async storeDocumentSelectionne(context: ActionContext<GedState, any>, payload: { document: DocumentGed }) {
    if (payload.document.nom) {
      context.commit('setDocumentSelectionne', payload.document);
    } else {
      try {
        const response = await GedAPI.fetchInfoDocumentGed(payload.document.id);
        if (response.data.succeeded && response.data.body) {
          const nomFichier =
            response.data.body.metadata.find((m: { key: string; value: string }) => m.key === 'nomfichier')?.value ??
            'fichier.pdf';
          const lu =
            response.data.body.metadata.find((m: { key: string; value: string }) => m.key === 'destlu')?.value == 0
              ? false
              : true;
          const typeDoc = response.data.body.metadata.find(
            (m: { key: string; value: string }) => m.key === 'typedoc'
          )?.value;
          const docFetched = { id: payload.document.id, nom: nomFichier, lu: lu, type: typeDoc } as DocumentGed;
          context.commit('setDocumentSelectionne', docFetched);
        } else throw new Error(response.data.message);
      } catch (error: any) {
        throwError(error);
      }
    }
  },
  async storeLazyParamsDatatableGed(
    context: ActionContext<GedState, any>,
    payload: { lazyParams: EvenementLazyLoading }
  ) {
    context.commit('setLazyParamsDatatableGed', payload.lazyParams);
  },
  async storeExploitationSelectionne(context: ActionContext<GedState, any>, payload: { exploitation: Cheptel }) {
    //retrait des données sensibles -> stockage dans la session storage
    const exploitation: Cheptel = {
      id: payload.exploitation.id,
      num_cheptel: payload.exploitation.num_cheptel,
      nom: payload.exploitation.nom,
    } as Cheptel;
    context.commit('setExploitationSelectionne', exploitation);
  },
};
