import { computed } from 'vue';
import store from '../store';

export const apiConfig = computed((): any => {
  return {
    headers: {
      Authorization: 'Bearer ' + store.getters['UtilisateurModule/getToken'],
      'Content-Type': 'application/json',
    },
  };
});

export const apiConfigSansHeader = computed((): any => {
  return { Authorization: 'Bearer ' + store.getters['UtilisateurModule/getToken'] };
});

export const apiConfigCSV = computed((): any => {
  return {
    Authorization: 'Bearer ' + store.getters['UtilisateurModule/getToken'],
  };
});

export const apiConfigPDF = computed((): any => {
  return {
    Authorization: 'Bearer ' + store.getters['UtilisateurModule/getToken'],
  };
});
