import { Organisme, Profil } from '@/models';
import ProfilState from './stateInterface';

export default {
  setProfilsOrganisme(state: ProfilState, payload: Profil[]) {
    state.profils = payload;
  },

  setAllProfilsOrganisme(state: ProfilState, payload: Organisme[]) {
    state.organismes = payload;
  },

  supprimerProfil(state: ProfilState, payload: { profil_id: number; ref_organisme_id: number }) {
    if (state.profils.length != 0) {
      state.profils = state.profils.filter((profil) => {
        return profil.id != payload.profil_id;
      });
    }
    if (state.organismes.length != 0) {
      const indexOrganisme = state.organismes.findIndex((organisme) => organisme.id === payload.ref_organisme_id);
      state.organismes[indexOrganisme].profils = state.organismes[indexOrganisme].profils!.filter((profil) => {
        return profil.id != payload.profil_id;
      });
    }
  },

  creerProfil(state: ProfilState, payload: Profil) {
    if (state.profils.length != 0) {
      state.profils.push(payload);
    }
    if (state.organismes.length != 0) {
      const indexOrganisme = state.organismes.findIndex((organisme) => organisme.id === payload.ref_organisme?.id);
      if (indexOrganisme != -1) state.organismes[indexOrganisme].profils!.push(payload);
    }
  },

  modifierProfil(state: ProfilState, payload: Profil) {
    if (state.profils.length != 0) {
      const index = state.profils.findIndex((profil) => profil.id == payload.id);
      state.profils[index].nom_profil = payload.nom_profil;
      state.profils[index].droits_profils = payload.droits_profils;
    }
    if (state.organismes.length != 0) {
      const indexOrganisme = state.organismes.findIndex((organisme) => organisme.id === payload.ref_organisme?.id);
      const index = state.organismes[indexOrganisme].profils!.findIndex((profil) => profil.id === payload.id);

      if (index != -1) state.organismes[indexOrganisme].profils![index] = payload;
    }
  },
};
