import RaceAPI from '@/api/RaceAPI';
import { ActionContext } from 'vuex';
import RaceState from './stateInterface';

export default {
  async fetchAllRaces(context: ActionContext<RaceState, any>) {
    const response = await RaceAPI.fetchRaces();
    if (response.data.succeeded) {
      context.commit('setRaces', response.data.body);
    } else {
      context.commit('setRaces', []);
      throw new Error(response.data.message);
    }
  },
};
