import mutations from './mutations';
import actions from './actions';
import getters from './getters';
import AlerteState from './stateInterface';
import type { Alerte, AlerteTabDeBord, Cheptel, EvenementLazyLoading } from '@/models';

export default {
  namespaced: true,
  state(): AlerteState {
    return {
      alertes: [] as Alerte[],
      alerte: {} as Alerte,
      alertesTDB: [] as AlerteTabDeBord[],
      cheptelsAlertes: [] as Cheptel[],
      lazyParamsDatatableAlertes: {} as EvenementLazyLoading,
    };
  },
  mutations,
  actions,
  getters,
};
