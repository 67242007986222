import mutations from './mutations';
import actions from './actions';
import getters from './getters';
import type { AdressePostale } from '@/models';
import AdressePostaleState from './stateInterface';

export default {
  namespaced: true,
  state(): AdressePostaleState {
    return {
      adresses_postales: [] as AdressePostale[],
    };
  },
  mutations,
  actions,
  getters,
};
